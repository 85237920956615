<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();
    import { fly, fade } from 'svelte/transition';
    import { quintOut } from 'svelte/easing';

    import { activePortal, portals } from "../../../store";
    import { getContext } from 'svelte';
    import api from "../../../api";
    import Callback from "../../Callback.svelte";

    // From Context
    const accessCode = getContext('accessCode');
    const portal = getContext('portal');

    // Visible
    export let show = false;
    let working = false;
    let callback = {
        date: '',
        time: ''
    }
    let complete = null;

    /* Functions */
    const requestCallback = async () => {
        working = true;
        complete = await api.autoAipCallback(portal, accessCode, callback.date.set(callback.time).toFormat("yyyy-MM-dd HH:mm"));
        working = false;
    }

    const reset = (open) => {
        complete = null;
        callback = {
            date: '',
            time: ''
        }
        working = false;
        show = open;
    }

    $: isSecondCharge = portal === "second-charge";

    let selectedPortal = portals.standard;
    activePortal.subscribe(c => {
        selectedPortal = c ? c : portals.standard;
    });

</script>

<div class="request-callback__wrapper" class:second-charge={isSecondCharge}>

    <button class="button fill mt" on:click={() => reset(!show) }>
        <svg x="0px" y="0px" width="100%" height="100%"
             viewBox="0 0 473.806 473.806" xml:space="preserve">
                <g>
                    <path d="M374.456,293.506c-9.7-10.1-21.4-15.5-33.8-15.5c-12.3,0-24.1,5.3-34.2,15.4l-31.6,31.5c-2.6-1.4-5.2-2.7-7.7-4
                        c-3.6-1.8-7-3.5-9.9-5.3c-29.6-18.8-56.5-43.3-82.3-75c-12.5-15.8-20.9-29.1-27-42.6c8.2-7.5,15.8-15.3,23.2-22.8
                        c2.8-2.8,5.6-5.7,8.4-8.5c21-21,21-48.2,0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5c-6-6.2-12.3-12.6-18.8-18.6
                        c-9.7-9.6-21.3-14.7-33.5-14.7s-24,5.1-34,14.7c-0.1,0.1-0.1,0.1-0.2,0.2l-34,34.3c-12.8,12.8-20.1,28.4-21.7,46.5
                        c-2.4,29.2,6.2,56.4,12.8,74.2c16.2,43.7,40.4,84.2,76.5,127.6c43.8,52.3,96.5,93.6,156.7,122.7c23,10.9,53.7,23.8,88,26
                        c2.1,0.1,4.3,0.2,6.3,0.2c23.1,0,42.5-8.3,57.7-24.8c0.1-0.2,0.3-0.3,0.4-0.5c5.2-6.3,11.2-12,17.5-18.1c4.3-4.1,8.7-8.4,13-12.9
                        c9.9-10.3,15.1-22.3,15.1-34.6c0-12.4-5.3-24.3-15.4-34.3L374.456,293.506z M410.256,398.806
                        C410.156,398.806,410.156,398.906,410.256,398.806c-3.9,4.2-7.9,8-12.2,12.2c-6.5,6.2-13.1,12.7-19.3,20
                        c-10.1,10.8-22,15.9-37.6,15.9c-1.5,0-3.1,0-4.6-0.1c-29.7-1.9-57.3-13.5-78-23.4c-56.6-27.4-106.3-66.3-147.6-115.6
                        c-34.1-41.1-56.9-79.1-72-119.9c-9.3-24.9-12.7-44.3-11.2-62.6c1-11.7,5.5-21.4,13.8-29.7l34.1-34.1c4.9-4.6,10.1-7.1,15.2-7.1
                        c6.3,0,11.4,3.8,14.6,7c0.1,0.1,0.2,0.2,0.3,0.3c6.1,5.7,11.9,11.6,18,17.9c3.1,3.2,6.3,6.4,9.5,9.7l27.3,27.3
                        c10.6,10.6,10.6,20.4,0,31c-2.9,2.9-5.7,5.8-8.6,8.6c-8.4,8.6-16.4,16.6-25.1,24.4c-0.2,0.2-0.4,0.3-0.5,0.5
                        c-8.6,8.6-7,17-5.2,22.7c0.1,0.3,0.2,0.6,0.3,0.9c7.1,17.2,17.1,33.4,32.3,52.7l0.1,0.1c27.6,34,56.7,60.5,88.8,80.8
                        c4.1,2.6,8.3,4.7,12.3,6.7c3.6,1.8,7,3.5,9.9,5.3c0.4,0.2,0.8,0.5,1.2,0.7c3.4,1.7,6.6,2.5,9.9,2.5c8.3,0,13.5-5.2,15.2-6.9
                        l34.2-34.2c3.4-3.4,8.8-7.5,15.1-7.5c6.2,0,11.3,3.9,14.4,7.3c0.1,0.1,0.1,0.1,0.2,0.2l55.1,55.1
                        C420.456,377.706,420.456,388.206,410.256,398.806z"/>
                    <path d="M256.056,112.706c26.2,4.4,50,16.8,69,35.8s31.3,42.8,35.8,69c1.1,6.6,6.8,11.2,13.3,11.2c0.8,0,1.5-0.1,2.3-0.2
                        c7.4-1.2,12.3-8.2,11.1-15.6c-5.4-31.7-20.4-60.6-43.3-83.5s-51.8-37.9-83.5-43.3c-7.4-1.2-14.3,3.7-15.6,11
                        S248.656,111.506,256.056,112.706z"/>
                    <path d="M473.256,209.006c-8.9-52.2-33.5-99.7-71.3-137.5s-85.3-62.4-137.5-71.3c-7.3-1.3-14.2,3.7-15.5,11
                        c-1.2,7.4,3.7,14.3,11.1,15.6c46.6,7.9,89.1,30,122.9,63.7c33.8,33.8,55.8,76.3,63.7,122.9c1.1,6.6,6.8,11.2,13.3,11.2
                        c0.8,0,1.5-0.1,2.3-0.2C469.556,223.306,474.556,216.306,473.256,209.006z"/>
                </g>
        </svg>
    </button>

    {#if show}
        <div class="request-callback__background" on:click|self={() => reset(!show) } transition:fade="{{delay: 100}}">
            <div class="request-callback" transition:fly="{{ delay: 100, duration: 300, x: -250, easing: quintOut }}">

                <header>
                    <div class="logo">
                        <img src="/{ selectedPortal.brand.logo }" alt="{ selectedPortal.brand.name }" />
                    </div>
                </header>

                <div class="request-callback__header">
                    <a href="#"  on:click|preventDefault={() => reset(!show)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 66 66">
                            <circle cx="33" cy="33" r="33" fill="#fff"/>
                            <g transform="translate(-6564 -205)">
                                <line id="Line_16" x2="27" y2="26" transform="translate(6583.084 225.935)" fill="none" stroke="#ff4900" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                <line id="Line_17" x1="27" y2="26" transform="translate(6583.084 225.935)" fill="none" stroke="#ff4900" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                            </g>
                        </svg>
                    </a>
                    <div></div>
                </div>

                <div class="request-callback__body">
                    <div class="request-callback__details__section">
                    {#if complete == null}
                        <h4>Request a call back</h4>
                        <form on:submit|preventDefault={requestCallback}>
                            <Callback bind:callback={callback}/>
                            <button
                                    type="submit"
                                    class="button green fill mt pill"
                                    disabled={!callback.date || !callback.time || working}
                            >Request Call
                            </button>
                        </form>
                    {:else}
                        {#if complete.success}
                            <h4>Thank you</h4>
                            <p>Your callback has been confirmed and we will be in touch.</p>
                        {:else}
                            <h4>Whoops</h4>
                            <form on:submit|preventDefault={() => reset(true)}>
                            <p>{complete.error}</p>
                            <button
                                    type="submit"
                                    class="button green fill mt pill"
                            >Try Again</button>
                            </form>
                        {/if}
                    {/if}
                    </div>
                </div>
            </div>
        </div>
    {/if}

</div>

<style>

.request-callback__wrapper {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}

.request-callback__wrapper > button {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    border-radius: 10rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width:3.5rem;
    height:3.5rem;
    padding:0.5rem;
    /* add drop shadow */
    -webkit-box-shadow: 0.25rem 0.25rem 0.25rem rgba(0,0,0,0.3);
            box-shadow: 0.25rem 0.25rem 0.25rem rgba(0,0,0,0.3);
    z-index:999;
}

.request-callback__wrapper > button > svg {
    fill: white;
}

.request-callback__background {
    z-index: 2000;
    position: fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color: rgba(0, 0, 0, 0.25);
}

.request-callback {
    z-index: 2001;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    width:30vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align:stretch;
        -ms-flex-align:stretch;
            align-items:stretch;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    overflow-y:scroll;
    background: rgb(107,179,198);
    background:
            linear-gradient(164deg,
            rgba(107,179,198,1) 0%,
            rgba(250,250,250,1) 45%,
            rgba(250,250,250,1) 100%);
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

@media(max-width: 1100px) {
    .request-callback {
        width: 40vw;
    }
}

@media(max-width: 850px) {
    .request-callback {
        width: 50vw;
    }
}

@media(max-width: 650px) {
    .request-callback {
        width: auto;
        right:0;
        top:0;
        bottom:0;
        border-radius: 0;
    }
}

.request-callback__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 0.5rem 1rem;
    color:white;
    background-color: #FF4900;
}

.request-callback__header a {
    text-decoration: none;
    color:white;
}
.request-callback__header a:hover{
    text-decoration: underline;
}

.request-callback__body {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    padding:1rem;
}

.request-callback h4 {
    font-size:1.2rem;
    margin-bottom:0.5rem;
    font-weight: bold;
}

.request-callback__details__section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width:300px;
    margin: 0 auto;
}

.request-callback__details__section > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.edit-details__details__section div > p {
    padding:0;
    margin:0;
    font-size:1.2rem;
    padding-bottom: 0.5rem;
}

.edit-details__details__section .mt {

    margin-top:0.5rem;
}

@media(max-width: 500px) {
    .request-callback__details__section div > span {
        max-width:100%;
    }

}

/* ==================== */
/* SECOND CHARGE COLOUR CHANGES */

.second-charge .request-callback__header {
    background-color: #00a854;
}

.second-charge .request-callback__header svg line {
    stroke: #00a854;
}

/* ==================== */
/* HEADER SECTION */

.request-callback header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding:0.5rem 1rem;
    background-color: #ffffff;
    border-bottom: 1px solid #cccccc;
    position: relative;
    z-index: 10;
}

.request-callback header > .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 1em;
}

.request-callback header > .container > div {
    text-align: right;
}

.request-callback header > .container > div > h3 {
    margin: 0;
}

.request-callback header > .container > div > p {
    margin: 0;
}

.request-callback header img {
    max-height: 50px;
}

.request-callback header .logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width:100%;
}</style>
