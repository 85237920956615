<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();
    import { activePortal} from "../../store";

    export let quote = null;

    $: title = `${quote.name ? `{quote.name}, ` : ``}Upload your documents here!`;
    
    function showUpload(desc, type) {
        dispatch('upload', {
            description: desc,
            type: type
        });
        history.pushState({}, "", "#upload")
    }
    
    function showExpenditure() {
        dispatch('expenditure');
        history.pushState({}, "", "#expenditure");
    }

    function next() {
        dispatch('next');
    }

</script>
<div class="step2">
    <div class="container">
        <h1>{title}</h1>
    </div>
    <div class="tasks">
        <div class="container">
            <div>
                <h2>Income</h2>
                <span>Your payslips and/or bank statements</span>
                {#if quote.proofOfIncomeProvided}
                    <svg
                        width="24"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        fill="green"
                        viewBox="0 0 24 24"
                    ><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                    </svg>
                {:else}
                    <button class="button" on:click={() => showUpload('Income', 'proof-of-income')}>Upload</button>
                {/if}
            </div>
            <div>
                <h2>Expenditure</h2>
                <span>Tell us what you spend each month</span>
                {#if quote.expenditureQuestionnaireCompleted}
                    <svg
                        width="24"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        fill="green"
                        viewBox="0 0 24 24"
                    ><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                    </svg>
                {:else}
                    <button class="button" on:click={showExpenditure}>Begin</button>
                {/if}
            </div>
<!--            <div>-->
<!--                <h2>Proof of Identity</h2>-->
<!--                <span>Optional</span>-->
<!--                {#if quote.identityDocumentProvided}-->
<!--                    <svg-->
<!--                        width="24"-->
<!--                        height="24"-->
<!--                        xmlns="http://www.w3.org/2000/svg"-->
<!--                        fill-rule="evenodd"-->
<!--                        clip-rule="evenodd"-->
<!--                        fill="green"-->
<!--                        viewBox="0 0 24 24"-->
<!--                    ><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />-->
<!--                    </svg>-->
<!--                {:else}-->
<!--                    <button on:click={() => dispatch('skipId')}>Skip</button>-->
<!--                    <button on:click={() => showUpload('Identity', 'identity-document')}>Upload</button>-->
<!--                {/if}-->
<!--            </div>-->
        </div>
    </div>

<div class="container next-steps">
        <button
                class="button green"
                on:click={() => next()}
                disabled="{!(quote.proofOfIncomeProvided && quote.expenditureQuestionnaireCompleted) ? `disabled` : ``}">Continue</button>
        <p>No documents to hand? Call us on <strong>{ $activePortal.brand.telephone }</strong>.</p>
    </div>

</div>

<style>


.next-steps {
    padding-top:1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 1rem;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
}

/* -------------------------------------------------- */
/* Terms */
/* -------------------------------------------------- */

.tasks .container div {
    background-color: #bfe5ee;
    border-radius: 0.5rem;
    padding-bottom:1rem;
}

.tasks .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    gap: 0.5rem;
}

.tasks .container h2 {
    margin-top:0;
}

.tasks .container > div {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 1rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;

}
.tasks .container > div > span {
    font-weight: bold;
    color: hsl(190, 64%, 30%);
    display: block;
    margin: 0 0 1rem 0;
}

.tasks .container > div > p {
    color: #124853;
    font-size: 1.75em;
}

@media screen and (max-width: 850px) {
    .tasks .container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .tasks .container > div > span,
    .tasks .container > div > p {
        display: block;
        margin:0;
        padding:0.5rem;
    }
}

/* ---------------------------------------- */
/* Button */
/* ---------------------------------------- */
.next-steps .button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 0.5rem;
}
.next-steps .button > span {
    white-space: pre-line;
}

@media screen and (min-width: 851px) {
    .next-steps .button {
        font-size:2em;
    }
}

@media screen and (max-width: 850px) {
    .next-steps .button {
        font-size: 1.5em;
    }
}</style>