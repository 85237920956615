<script>
    import { createEventDispatcher, onMount } from "svelte";
    import { formatLoanTerm } from "../../utils";
    import api from "../../api";
    import {activePortal} from "../../store";

    onMount(async () => {
        await api.aipPageView();
    });

    const dispatch = createEventDispatcher();

    export let quote = null;

    function next() {
        dispatch('next');
    }

</script>

<div class="step1">
    <div class="container">
        <h1>Great News!</h1>
        <h3>Your { $activePortal.term } is Approved In Principle</h3>
    </div>
    <div class="terms">
        <div class="container">
            <div>
                <span>Borrowing</span>
                <p>&pound;{ quote.loanAmount.toLocaleString("en-GB") }</p>
            </div>
            <div>
                <span>Term</span>
                <p>{ formatLoanTerm(quote.loanTerm) }</p>
            </div>
            <div>
                <span>Initial Monthly Payment</span>
                <p>
                    &pound;{
                    quote.initialMonthlyPayment.toLocaleString("en-GB", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })
                }
                </p>
            </div>
        </div>
    </div>
    <div class="container next-steps">
        <a class="button green" href="#" on:click|preventDefault={() => {next()}}>Complete My Application</a>
        <a class="button green call-us" href="tel:+441302590873">Call Us</a>
        <p>
            You can call us on <strong>{ $activePortal.brand.telephone }</strong>.
        </p>
    </div>
</div>

<style>

.next-steps {
    padding-top:1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 1rem;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
}

/* -------------------------------------------------- */
/* Terms */
/* -------------------------------------------------- */

.terms .container div {
    background-color: #bfe5ee;
    border-radius: 0.5rem;
}

.terms .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    gap: 0.5rem;
}

.terms .container > div {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 0.5em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;

}
.terms .container > div > span {
    font-weight: bold;
    color: hsl(190, 64%, 30%);
    display: block;
    margin: 1em 0;
}

.terms .container > div > p {
    color: #124853;
    font-size: 1.75em;
}

@media screen and (max-width: 850px) {
    .terms .container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .terms .container > div > span,
    .terms .container > div > p {
        display: block;
        margin:0;
        padding:0.5rem;
    }
}

/* ---------------------------------------- */
/* Button */
/* ---------------------------------------- */
.button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 0.5rem;
}
.button > span {
    white-space: pre-line;
}

@media screen and (min-width: 851px) {
    .button {
        font-size:2em;
    }
    .call-us {
        display: none;
    }
}

@media screen and (max-width: 850px) {
    .button {
        font-size: 1.5em;
    }
    .call-us {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}</style>
