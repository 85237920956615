<script>
    import {onMount} from "svelte";
    import {router} from "tinro";
    import api from "../../Shared/api";
    import {user} from "../../Shared/store";
    import LoadingIndicator from "../../Shared/Components/LoadingIndicator.svelte";

    export let documentCode, signerCode;

    let working = false;
    let eSignUrl;

    // eversign events
    window.addEventListener("message", e => {
        if (e.data === "event_loaded") {
        } else if (e.data === "event_signed") {
            eSignUrl = null;
            router.goto("/e-sign/complete?event=signing_complete");
        } else if (e.data === "event_declined") {
            eSignUrl = null;
        } else if (e.data === "event_error") {
            eSignUrl = null;
        }
        working = false;
    });

    onMount(async () => {
        working = true;
        const result = await api.eSignByCode(documentCode, signerCode);
        if (result.success) {
            eSignUrl = result.redirect;
            return;
        } else {
            router.goto("/");
        }
    })

    function back() {
        router.goto("/documents");
    }
</script>

{#if eSignUrl}
    <div class="esign">
        <iframe title="E-Signatures" src={eSignUrl} frameborder="0"></iframe>
    </div>
{/if}

<LoadingIndicator description="Please wait..." bind:show={working}/>

<style>
div.esign {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 110;
}

div.esign > iframe {
    width: 100%;
    height: 100%;
}</style>