<script>
    import {onMount} from "svelte";
    import {router, Route} from "tinro";

    import {signOut, user, activePortal, caseList, defaultPortal} from "./Shared/store";
    import Protected from "./Views/Protected.svelte";

    import Preview from "./Views/Preview.svelte";
    import NotFound from "./Views/NotFound.svelte";

    import Home from "./Views/Home.svelte";
    import Documents from "./Views/Documents.svelte";
    import Todo from "./Views/Todo.svelte";

    import ESign from "./Views/ESign/ESign.svelte";
    import ESignComplete from "./Views/ESign/ESignComplete.svelte";

    import Reset from "./Views/Account/Reset.svelte";
    import Register from "./Views/Account/Register.svelte";
    import SignIn from "./Views/Account/SignIn.svelte";

    import SmartSearchComplete from "./Views/SmartSearch/SmartSearchComplete.svelte";

    import Account from "./Shared/Layouts/Elements/Account.svelte";
    import AIP from "./Views/AIP/AIP.svelte";

    import CompletePasswordReset from "./Views/Account/CompletePasswordReset.svelte";
    import AIPAuto from "./Views/AIPAuto/AIPAuto.svelte";
    import AIPAutoLander from "./Views/AIPAuto/Lander.svelte";

    import WebGuide from "./Views/Guide/Web/WebGuide.svelte";
    import AcceptableDocuments from "./Views/Guide/AcceptableDocuments.svelte";
    import AppGuide from "./Views/Guide/App/AppGuide.svelte";
    import Guides from "./Views/Guide/Guides.svelte";
    import Protection from "./Views/Protection.svelte";
    import ThirdPartyConsent from "./Views/ThidParty/ThirdPartyConsent.svelte";

    let currentPath = null;

    onMount(async () => {
        if (!true) {
            console.log("Development Mode");
        }
        router.subscribe((x) => {
            currentPath = x.path;
            window.scrollTo(0, 0);
        });
    })

    let portal = defaultPortal.standard;
    activePortal.subscribe(c => {
        portal = c ? c : defaultPortal.standard;
    });

    $: trustPilotHeader = portal && portal.trustPilot && portal.trustPilot.businessUnitId && currentPath && currentPath.startsWith("/quote");
</script>
<header>
    <div class="logo">
        <img src="/{ portal.brand.logo }" alt="{ portal.brand.name }"/>
    </div>
    {#if $user.signedIn}
        <Account/>
    {:else}
        {#if trustPilotHeader}
        <!-- TrustBox widget - Micro Star -->
        <div class="trustpilot-widget" data-locale="en-GB" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="{ portal.trustPilot.businessUnitId }" data-style-height="24px" data-style-width="268px" data-theme="light">
          <a href="https://uk.trustpilot.com/review/{ portal.brand.domain }" target="_blank" rel="noopener">Trustpilot</a>
        </div>
        <!-- End TrustBox widget -->
        {/if}
    {/if}
</header>

<div class="content">
    <Route>
        <Route path="/">
            <Protected>
                <Home/>
            </Protected>
        </Route>
        <Route path="/documents">
            <Protected>
                <Documents/>
            </Protected>
        </Route>
        <Route path="/todo">
            <Protected>
                <Todo/>
            </Protected>
        </Route>
        <Route path="/protection">
            <Protected>
                <Protection/>
            </Protected>
        </Route>
        <Route path="/smart-search/complete">
            <SmartSearchComplete/>
        </Route>
        <Route path="/e-sign/complete">
            <ESignComplete/>
        </Route>
        <Route path="/e-sign/:code1/:code2" let:params>
            <ESign documentCode={params.code1} signerCode={params.code2}/>
        </Route>
        <Route path="/reset">
            <Reset/>
        </Route>
        <Route path="/reset/:code" let:params>
            <CompletePasswordReset code={params.code}/>
        </Route>
        <Route path="/register">
            <Register/>
        </Route>
        <Route path="/sign-in">
            <SignIn/>
        </Route>

        <Route path="/preview/*">
            <Route path="/:accountId/:product/:reference" let:params>
                <Preview accountId={params.accountId} product={params.product} reference={params.reference}/>
            </Route>
            <Route path="/:accountId" let:params>
                <Preview accountId={params.accountId}/>
            </Route>
        </Route>

        <Route path="/aip/*">
            <Route path="/f/:accessCode" let:params>
                <AIP portal="first-charge" accessCode={params.accessCode}/>
            </Route>
            <Route path="/s/:accessCode" let:params>
                <AIP portal="second-charge" accessCode={params.accessCode}/>
            </Route>
            <Route path="/g/:accessCode" let:params>
                <AIP portal="specialist-finance" accessCode={params.accessCode}/>
            </Route>
        </Route>

        <Route path="/quote/*">
            <Route path="/f/:accessCode" let:params>
                <AIPAuto portal="first-charge" accessCode={params.accessCode}/>
            </Route>
            <Route path="/s/:accessCode" let:params>
                <AIPAuto portal="second-charge" accessCode={params.accessCode}/>
            </Route>
            <Route path="/g/:accessCode" let:params>
                <AIPAuto portal="specialist-finance" accessCode={params.accessCode}/>
            </Route>
        </Route>

        <Route path="/welcome/*">
            <Route path="/f/" let:params>
                <AIPAutoLander portal="first-charge" />
            </Route>
            <Route path="/f/:accessCode" let:params>
                <AIPAutoLander portal="first-charge" accessCode={params.accessCode}/>
            </Route>
            <Route path="/s/" let:params>
                <AIPAutoLander portal="second-charge" />
            </Route>
            <Route path="/s/:accessCode" let:params>
                <AIPAutoLander portal="second-charge" accessCode={params.accessCode}/>
            </Route>
            <Route path="/g/" let:params>
                <AIPAutoLander portal="specialist-finance" />
            </Route>
            <Route path="/g/:accessCode" let:params>
                <AIPAutoLander portal="specialist-finance" accessCode={params.accessCode}/>
            </Route>
        </Route>

        <Route path="/guide">
            <Guides/>
        </Route>
        <Route path="/guide/web">
            <WebGuide/>
        </Route>
        <Route path="/guide/app">
            <AppGuide/>
        </Route>
        <Route path="/guide/acceptable-documents">
            <AcceptableDocuments/>
        </Route>

        <Route path="/partner/welcome/f/:partner/:code" let:params>
            <ThirdPartyConsent portal="first-charge" code={params.code} partner={params.partner} />
        </Route>
        <Route path="/partner/welcome/s/:partner/:code" let:params>
            <ThirdPartyConsent portal="second-charge" code={params.code} partner={params.partner} />
        </Route>
        <Route path="/partner/welcome/g/:partner/:code" let:params>
            <ThirdPartyConsent portal="specialist-finance" code={params.code} partner={params.partner} />
        </Route>
        <Route path="/partner/welcome/p/:partner/:code" let:params>
            <ThirdPartyConsent portal="protect" code={params.code} partner={params.partner} />
        </Route>

        <Route fallback>
            <NotFound/>
        </Route>
    </Route>
</div>

<style>

:root {
    --range-handle-inactive: rgba(1, 181, 122, 1); /* inactive handle color */
    --range-handle: #17174c; /* non-focussed handle color */
    --range-handle-focus: rgb(5, 152, 104); /* focussed handle color */
}

:global(html), :global(body) {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 370px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

:global(body) {
    /*white-space: pre-line;*/
    color: #2c3e50;
    background-color: #fafafa;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

:global(.container) {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
}

:global(.content) {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}

:global(.text-left) {
    text-align: left !important;
}

:global(input, select) {
    padding: 0.5em;
    border: 1px solid #cccccc;
    outline: none;
    max-width: 320px;
    font-size: 1em;
    margin: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 0.25rem;
}

:global(.form label) {
    font-weight: bold;
}

:global(.pulse) {
    -webkit-animation: pulse-animation 1.75s infinite;
            animation: pulse-animation 1.75s infinite;
}

:global(.button, .button:visited) {
    --button-color: 23, 23, 76;
    border: 2px solid rgba(var(--button-color), 1);
    background-color: white;
}

:global(.button.red) {
    --button-color: 221, 29, 66;
}

:global(.button.green) {
    --button-color: 1, 181, 122;
}

:global(.button.orange) {
    --button-color: 255, 73, 0;
}

:global(.button.fill) {
    background-color: rgba(var(--button-color), 1);
    border: 2px solid rgba(var(--button-color), 1);
    color: white;
}

:global(.button:hover) {
    opacity: 0.9;
}

:global(.button) {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 0.3rem;
    cursor: pointer;
    font-size: 1.25rem;
    color: rgba(var(--button-color), 1);
}

:global(.button.pill) {
    border-radius: 100px;
}

:global(.button.mt) {
    margin-top: 1rem;
}

:global(.button.mb) {
    margin-bottom: 1rem;
}

:global(.button.small) {
    font-size: 0.75rem;
}

:global(.button.small.mt) {
    margin-top: 0.5rem;
}

:global(.button.small.mb) {
    margin-bottom: 0.5rem;
}

:global(.button.no-cursor) {
    cursor: default;
}

:global(.button:hover, .button.active, .button:hover) {
    background-color: rgba(var(--button-color));
    color: white;
}

:global(.button:disabled) {
    --button-color: #cccccc;
}

@-webkit-keyframes pulse-animation {
    0% {
        -webkit-box-shadow: 0 0 0 0px rgba(var(--button-color), 0.5);
                box-shadow: 0 0 0 0px rgba(var(--button-color), 0.5);
    }
    100% {
        -webkit-box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
                box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

@keyframes pulse-animation {
    0% {
        -webkit-box-shadow: 0 0 0 0px rgba(var(--button-color), 0.5);
                box-shadow: 0 0 0 0px rgba(var(--button-color), 0.5);
    }
    100% {
        -webkit-box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
                box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

:global(.form .error) {
    color: #dd1d42;
}

:global(.capitalize) {
    text-transform: capitalize;
}

:global(.m-auto) {
    margin-left: auto;
    margin-right: auto;
}

:global(img.img-fluid) {
    display: block;
    max-width: 100%;
    height: auto;
}

header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 1rem;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0.5rem 1rem;
    background-color: #ffffff;
    border-bottom: 1px solid #cccccc;
    position: relative;
    z-index: 10;
}

header > .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 1em;
}

header > .container > div {
    text-align: right;
}

header > .container > div > h3 {
    margin: 0;
}

header > .container > div > p {
    margin: 0;
}

header img {
    max-height: 75px;
}

header .logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
}

@media screen and (max-width: 767px) {
    :global(.form) {
        grid-template-columns: 100%;
    }

    :global(.form input) {
        max-width: none;
    }


    header > .container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    header > .container > div {
        text-align: center;
    }

    header img {
        max-height: 50px;
    }

    header .logo {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }

    /*.content {*/
    /*    margin-top: 4em;*/
    /*}*/

}

@media screen and (max-width: 500px) {
    header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
}</style>
