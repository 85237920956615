<script>

    export let description = "Loading...";
    export let label = '';
    export let show = true

</script>

<div class="loading-indicator" class:show={show}>
    <svg
            class="loading"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            height="4em"
            width="4em"
    >
        <circle
                cx="50"
                cy="50"
                r="40"
                stroke="#2691a6"
                stroke-width="10"
                fill="none"
                stroke-dasharray="226.194 226.194"
        />
    </svg>
    <h2>{description}</h2>
    {#if label}
        <h3>{label}</h3>
    {/if}
</div>

<style>

/* WORKING */
.loading-indicator {
    margin-top:1rem;
    border-radius: 1rem;
    padding-top:1em;
    background-color: #ffffff;
    z-index: 100;
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
}

.loading-indicator.show {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.loading-indicator > svg {
    -webkit-animation: rotating 1s linear infinite;
            animation: rotating 1s linear infinite;
}</style>