<NoSidebar title="Acceptable Documents/Image Quality" titleClass="text-left">

    <a href="/guide">Back to Guides</a>

    <div class="acceptable-documents">

        <h3>Proof of Income</h3>

        <div class="row">
            <div class="col">
                <h4>Wage Slips</h4>
                <p>What we need to see:</p>
                <ul>
                    <li>3 most recent consecutive wage slips, i.e. Jan, Feb and Mar or last 12 weekly payslips</li>
                    <li>Your name</li>
                    <li>Date</li>
                    <li>Gross Pay, Net Pay</li>
                    <li>Adjustments</li>
                    <li>All other information</li>
                </ul>
            </div>
            <div class="col">
                <img src="/guide/documents/wageslips.png" />
            </div>
        </div>

        <div class="row">
            <div class="col">
                <h4>Self Employed SA302</h4>
                <p>What we need to see:</p>
                <ul>
                    <li>Your 2 nost recent SA302 documents</li>
                    <li>Tax Year Overview must be included</li>
                    <li>Your name</li>
                    <li>Total amount paid</li>
                    <li>Adjustments</li>
                </ul>
            </div>
            <div class="col">
                <img src="/guide/documents/sa302.png" />
            </div>
        </div>

        <div class="row">
            <div class="col">
                <h4>Pension</h4>
                <p>What we need to see:</p>
                <ul>
                    <li>Your name</li>
                    <li>Documents date</li>
                    <li>Total received</li>
                    <li>When the payments are made</li>
                    <li>As what we need to see could be on different pages this may require multiple screenshots/photos to be uploaded</li>
                </ul>
            </div>
            <div class="col">
                <img src="/guide/documents/pension.png" />
            </div>
        </div>

        <h3>Proof of Address</h3>

        <div class="row">
            <div class="col">
                <h4>Household Bills</h4>
                <p>Gas, Electricity, Water, Landline Phone, TV Licence and Home Insurance bills are accepted. Unfortunately, lenders will not accept mobile phone bills.</p>
                <p>What we need to see:</p>
                <ul>
                    <li>Your name</li>
                    <li>Documents date which must be dated within the last 3 months.</li>
                    <li>Your address</li>
                    <li>Full page shown including documents content</li>
                </ul>
            </div>
            <div class="col">
                <img src="/guide/documents/household_bills.png" />
            </div>
        </div>

        <div class="row">
            <div class="col">
                <h4>Driving Licence</h4>
                <p>Provisional driving licences are accepted.</p>
                <p>What we need to see:</p>
                <ul>
                    <li>Your name</li>
                    <li>Your photo</li>
                    <li>Your address</li>
                    <li>Must be valid (in date)</li>
                </ul>
            </div>
            <div class="col">
                <img src="/guide/documents/drivinglicence.png" />
            </div>
        </div>

        <div class="row">
            <div class="col">
                <h4>Council Tax</h4>
                <p>What we need to see:</p>
                <ul>
                    <li>Your name</li>
                    <li>Your address</li>
                    <li>Date must be within 12 months</li>
                    <li>Full page shown including documents content</li>
                </ul>
            </div>
            <div class="col">
                <img src="/guide/documents/counciltax.png" />
            </div>
        </div>

        <h3>Documents for ID</h3>

        <div class="row">
            <div class="col">
                <h4>UK or EU Passport</h4>
                <p>What we need to see:</p>
                <ul>
                    <li>Your name</li>
                    <li>Your photo</li>
                    <li>Must be valid (in date)</li>
                    <li>The full page</li>
                </ul>
            </div>
            <div class="col">
                <img src="/guide/documents/passport.png" />
            </div>
        </div>

        <div class="row">
            <div class="col">
                <h4>Driving Licence</h4>
                <p>Provisional driving licences are accepted.</p>
                <p>What we need to see:</p>
                <ul>
                    <li>Your name</li>
                    <li>Your photo</li>
                    <li>Your address</li>
                    <li>Must be valid (in date)</li>
                </ul>
            </div>
            <div class="col">
                <img src="/guide/documents/drivinglicence.png" />
            </div>
        </div>

        <h4>Image Quality</h4>
        <p>A number of documents are rejected because of the quality of the image. Images must not have fingers blocking parts of the image, the image must not have flash or glare and the image must be of a high quality and not blurred.</p>

        <div class="img-aside">
            <img src="/guide/documents/image_quality_good.png" />
            <img src="/guide/documents/image_quality_bad.png" />
        </div>

        <ul>
            <li>All images must be clear, not blurred or partially blocked by fingers.</li>
            <li>Try and keep the document at flat as possible without distortion.</li>
            <li>Always try and provide a high-quality image that is readable.</li>
        </ul>

    </div>

</NoSidebar>

<script>
    import NoSidebar from '../../Shared/Layouts/NoSidebar.svelte'
</script>

<style type="text/css">
.acceptable-documents {
    text-align: left;
}
.acceptable-documents p,
.acceptable-documents ul li {
    line-height: 1.75rem;
}
.acceptable-documents img {
    border: 1px solid #17174c;
    border-radius: 5px;
    margin: 2rem auto;
    max-width: 400px;
    max-height: 500px;
}
.acceptable-documents .img-aside {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
}
.acceptable-documents .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-bottom: 1px solid #17174c;
}
@media screen and (max-width: 700px) {
    .acceptable-documents img {
        max-width: 100%;
    }
}
@media screen and (max-width: 1000px) {
    .acceptable-documents .row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
}
@media screen and (max-width: 1200px) {
    .acceptable-documents .img-aside {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
}</style>