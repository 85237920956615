<script>

    import {getContext, createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    /* From Context */
    const accessCode = getContext('accessCode');
    const portal = getContext('portal');


</script>

<div class="container auto-aip__unavailable">
    <h1>Thank you!</h1>
    <p>We need to speak to you to continue with your quote.</p>
    <p>We will call you from 01302 591 320 as soon as we can, or if you prefer you can call us on this number.</p>
</div>

<style>

.auto-aip__unavailable {
    width: 100%;
    margin-top: 1rem;
    padding-top: 1rem;
    background-color: white;
    border: 3px solid #01b57a;
    border-radius: 0.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-line-pack: stretch;
        align-content: stretch;
    text-align: center;
}


p {
    line-height: 25px;
}

p:last-child {
    margin-bottom: 2rem;
}</style>