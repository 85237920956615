<script>

    export let stepIndex = 0;
    export let steps = [];

</script>

<div class="simple-stepper__steps-list">
    {#if steps.length}
        {#each steps as step, index}
            <div
                class="simple-stepper__steps-item"
                class:start={index === 0}
                class:end={index === steps.length - 1}
            >
                {#if index < stepIndex}
                    <div class="simple-stepper__steps-item-circle simple-stepper__steps-item-complete">&#10004;</div>
                {:else}
                    <div class="simple-stepper__steps-item-circle simple-stepper__steps-item-number">{(index+1).toString()}</div>
                {/if}
                <div class="simple-stepper__steps-item-title">{step}</div>
            </div>
        {/each}
        <div class="simple-stepper__steps-line"></div>
    {/if}
</div>

<style>

 .simple-stepper__steps-list {
     position: relative;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     z-index:100;
     min-height: auto;
     margin: 0 1rem;
     -webkit-box-orient: horizontal;
     -webkit-box-direction: normal;
         -ms-flex-direction: row;
             flex-direction: row;
     -webkit-box-pack: justify;
         -ms-flex-pack: justify;
             justify-content: space-between;
     justify-items: center;
     -webkit-box-align: start;
         -ms-flex-align: start;
             align-items: start;
}

.simple-stepper__steps-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 5rem;
    z-index: 100;
    text-align: center;
}

 .simple-stepper__steps-item .start {
     -webkit-box-align: start !important;
         -ms-flex-align: start !important;
             align-items: flex-start !important;
 }

 .simple-stepper__steps-item .end {
     -webkit-box-align: end !important;
         -ms-flex-align: end !important;
             align-items: flex-end !important;
 }

.simple-stepper__steps-line {
    border-top: 2px solid #01b57a;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    position: absolute;
    top: 1.6rem;
    left: 2rem;
    right: 2rem;
    z-index:99;
}

.simple-stepper__steps-item-title {
    margin-top:0.5rem;
    color:white;
}

.simple-stepper__steps-item-circle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width:3rem;
    height:3rem;
    margin:0;
    font-size:1.25rem;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #01b57a;
    font-family: "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    color: #01b57a;
    font-weight: bold;
    aspect-ratio: 1;
}

.simple-stepper__steps-item-complete {
    background: #01b57a;
    color:white;
}

@media (max-width: 650px) {
    .simple-stepper__steps-list {
        margin: 0 0.5rem;
    }
    .simple-stepper__steps-item-title {
        font-size: 12px;
    }

}</style>