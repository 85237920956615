<script>

    import {onMount, setContext} from "svelte";
    import {activePortal} from "../../Shared/store";
    import {portals} from "../../Shared/portals";

    // General
    import NoSidebar from "../../Shared/Layouts/NoSidebar.svelte";
    import TrustBox from "../../Shared/Components/TrustBox.svelte";
    import Cartoon from "./Heros/Cartoon.svelte";
    import Picture from "./Heros/Picture.svelte";
    import CallFloatingCTA from "../../Shared/Components/AIPAuto/Components/CallFloatingCTA.svelte";
    import AppForm from "../../Shared/Components/Apply/AppForm.svelte";

    import {meta} from 'tinro';
    const routeMeta = meta();

    // External Vars
    export let accessCode = "";
    export let portal;

    // Set context for children (use getContext on children to get values)
    setContext('accessCode', accessCode);
    setContext('portal', portal);

    // Set Active Portal
    activePortal.set(portals.all.find(x => x.api === portal));

    //
    // Computed
    //
    $: isSecondCharge = portal === "second-charge";

    //
    // App Form
    //
    import tracking from "../../Shared/Components/Apply/tracking";
    window.ClickAppTrack = tracking();
    function getQuoteStarted(ac) {
        let uri = `/quote/${$activePortal.slug}/${ac}`
        window.location = uri;
    }
    function getStarted() {
        if(accessCode) {
            getQuoteStarted(accessCode);
            return;
        }
        if (!showApplyForm) {
            if (form === null) {
                let options = $activePortal.form.modes();
                if (options.length === 1) {
                    form = options[0];
                } else {
                    showFormOptions = true;
                    return;
                }
            }
            showFormOptions = false;
            loadingApplyForm = true;
            showApplyForm = true;
            const ele = document.getElementById('form-top');
            setTimeout(() => {
                window.scroll({behavior: "smooth", top:ele.offsetTop});
            }, 500);
        }
    }
    let showApplyForm = false;
    let loadingApplyForm = false;
    let loadedAppForm = false;
    let showFormOptions = false;
    let form = null;

    //
    // Callback
    //
    let showCallback = false;
    function getCallback() {
        showCallback = true;
    }

</script>

<NoSidebar class="no-sidebar__auto-aip-lander__container" transparent nomargin>

    {#if isSecondCharge === true }
        <Cartoon/>
    {:else}
        <Picture/>
    {/if}

    <div class="min-content-width">
        <div>
            <h3>Quick, convenient { $activePortal.term.toLowerCase() } quotes</h3>
            <h4>When you’re looking for a { $activePortal.term.toLowerCase() }, we Believe it should be quick and simple to get a quote.</h4>
            <p>Just enter a few details and get your { $activePortal.term.toLowerCase() } quotes fast – then you can compare competitive rates at your convenience.</p>
        </div>
    </div>

    <div id="form-top" class="auto-aip-lander orange-strip">
        <div class="min-content-width">
            <h3>Let's get started on your {$activePortal.term.toLowerCase()} quote...</h3>
        </div>
    </div>

    <div class="auto-aip-lander blue-background">
        {#if !loadedAppForm && !showFormOptions}
            <div class="min-content-width">
                <div class="auto-aip-lander__form">
                    <div class="block">
                        <button
                                class="button fill green pill pulse"
                                on:click|self={() => { getStarted() }}>{loadingApplyForm ? 'Loading...' : 'Get Started'}</button>
                    </div>
                </div>
            </div>
        {/if}
        {#if showFormOptions}
            <div class="min-content-width">
                <div class="auto-aip-lander__form">
                    <div class="block">
                        {#each $activePortal.form.modes() as mode}
                            <button
                                    class="button fill green"
                                    on:click|self={() => { form = mode; getStarted() }}>{mode.mode}</button>
                        {/each}
                    </div>
                </div>
            </div>
        {/if}
        {#if showApplyForm}
            <div class="auto-aip-lander--form">
                <AppForm
                        url={$activePortal.form.url()}
                        form={form}
                        meta={routeMeta}
                        on:loaded={() => {loadingApplyForm = false; loadedAppForm = true; }}
                        on:quote={(e) => { getQuoteStarted(e.detail); }}
                />
            </div>
        {/if}
    </div>

    <div slot="footer">
        <footer>
            <div class="container">
                <TrustBox cssClass="padding-top"
                          templateId={ $activePortal.trustPilot.templateId }
                          businessUnitId={ $activePortal.trustPilot.businessUnitId }
                          domain={ $activePortal.brand.domain }
                />
            </div>
            <p>
                This Indicative Quote does not constitute a mortgage offer and does not entitle its recipients to a mortgage advance from the lender. Exact figures and interest rates will be fully explained in your Mortgage Illustration, please ensure you fully read through this once you receive it. Any offer of a mortgage is subject to the prevailing terms and conditions and prior to the lenders full underwriting assessment.
            </p>
            {#if portal === "second-charge"}
            <p>
                <strong>Representative Example</strong><br />
                If you borrow £30,000 over 180 months at a rate of 7.84% fixed for 5 years, you will pay 60 instalments of £309.72 per month followed by 120 months at a rate of 8.59% of £319.96 and a total amount payable of £57,153.40. This includes a broker fee of £1,995, a lender fee of £695 and the net loan interest of £24,253.85. The overall cost for comparison is 8.70% APRC Variable.
            </p>
            {/if}
            {#if portal !== "second-charge"}
                <p>
                <strong>Representative Example</strong><br/>
                    A mortgage of £135,000.00 payable over 20 years, initially on a fixed rate for 5 years at 4.59% and then on a variable rate of 6.29% for the remaining term would require 63 payments of £866.04 followed by payments of £966.15. The total amount payable would be £226,564.07 made up of the loan amount plus interest (£90,077.17) and fees (£995). The overall cost for comparison is 6.0% APRC representative.
                </p>
                <p>
                    A mortgage of £135,000.00 payable over 20 years, initially on a tracker rate for 2 years at 3.24% and then on a variable rate of 6.49% for the remaining term would require 24 payments of £770.69 followed by payments of £990.41. The total amount payable would be £233,500.12 made up of the loan amount plus interest (£96,426.38) and fees (£995). The overall cost for comparison is 6.1% APRC representative.
                </p>
            {/if}
            <p>
                <strong
                >Your home may be repossessed if you do not keep up repayments on your mortgage.</strong
                >
            </p>
        </footer>
    </div>

    <CallFloatingCTA bind:show="{showCallback}"/>

</NoSidebar>

<style>

.auto-aip-lander.orange-strip {
    background-color: #E65A27;
    color: white;
    padding: 0;
}

.auto-aip-lander.orange-strip h3 {
    font-size:1.2em;
    font-weight: normal;
    margin: 0;
    padding: 0.5rem 0;
}

.auto-aip-lander.blue-background {
    background: #DAEAEF;
    text-align: left;
}

.auto-aip-lander.blue-background .min-content-width {
    padding-top:2rem;
    padding-bottom: 2rem;
}

.auto-aip-lander__form > div.block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    justify-items: left;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.auto-aip-lander__form .button {
    margin: 1rem auto;
    font-size:2em;
}

.auto-aip-lander__box {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
    padding:0px 1rem 1rem 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-line-pack: stretch;
        align-content: stretch;
}

.auto-aip-lander__callback {
    text-align: center;
    background-color: white;
    margin: 0 auto;
}

.auto-aip__noquotes__info__callback .button {
    margin-top: 1rem;
}


footer {
    background-color: #ffffff;
    padding: 1em;
    border-top: 1px solid #cccccc;
}

.min-content-width {
    margin: 0 auto;
    max-width: 850px;
    padding:1rem 1.5rem 1rem 1rem;
}

:global(.no-sidebar__auto-aip-lander__container) {
    background: white none !important;
}

:global(.no-sidebar__auto-aip-lander__container .sidebar__main__card__body) {
    margin:0;
}

:global(.no-sidebar__auto-aip-lander__container .sidebar__main__card__body > h1) {
    display: none;
}

@media screen and (max-width: 850px) {
    .min-content-width {
        max-width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .min-content-width {
        font-size: 0.9em;
    }
}
@media screen and (max-width: 450px) {
    .min-content-width {
        font-size: 0.9em;
    }
}</style>
