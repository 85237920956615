<script>
    import NoSidebar from '../../Shared/Layouts/NoSidebar.svelte'
  import api from "../../Shared/api";
  import {router} from "tinro";
  import recaptcha from "../../Shared/recaptcha.js";
  import {signIn as setUserSignedIn} from "../../Shared/store";

  let email = "", password = "", error = "", emailCheck = false;
  let working = false;

  function handleEnter(e) {
    if (!working && e.key === "Enter") {
      register();
    }
  }

  async function register() {
    error = "";
    if (!email) {
      error = "Please enter your e-mail address"
      return;
    }
    if (!/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(email.trim())) {
      error = "Please enter a valid e-mail address";
      return;
    }
    if (!password) {
      error = "Please enter your password"
      return;
    }
    if (password.length < 8) {
      error = "Please choose a password with at least 8 characters"
      return;
    }
    working = true;

    recaptcha.action("register", async (token) => {
      const result = await api.register(email, password, token);
      console.log("Registration result", result);
      working = false;
      if (!result.success) {
        error = result.error || "Unable to register right now, please try again later.";
        return;
      } else if (!result.token) {
        emailCheck = true;
        return;
      } else {
        const user = {
          token: result.token,
          profile: {name: email},
          expires: Date.now() + 3600000,
          requiresVerification: true
        };
        setUserSignedIn(user);
        router.goto("/");
      }
    });

  }
</script>
<NoSidebar title="Register for an account">
    <div class="register container">
        {#if emailCheck}
            <p>Thank you for registering for an account. Please check your e-mail for further instructions.</p>
            <p><button on:click={() => router.goto("/")}>Home</button></p>
        {:else}
            <p>
                To register for an account on our customer portal, please enter your e-mail address below and choose a
                password.
            </p>
            <p>
                Please make sure you use the e-mail address you gave us on your application to allow us to locate your
                details, and be sure to choose a password with at least 8 characters.
            </p>
            <div class="form">
                <label for="email">E-mail Address:</label>
                <input id="email" type="email" bind:value={email} on:keyup={handleEnter}/>
                <label for="password">Password:</label>
                <input id="password" type="password" bind:value={password} on:keyup={handleEnter}/>
            </div>
            <span class="error">{error}</span>
            <button class="button" disabled={working} on:click={register}>{ working ? "Please Wait..." : "Register" }</button>
            <div class="links">
                <p>
                    <a href="/sign-in">Already have an account? Sign in here!</a>
                </p>
            </div>
        {/if}
    </div>
</NoSidebar>

<style>
.register {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.form {
    margin-top: 1rem;
    margin-bottom:1rem;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1em;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.links {
    margin-top:1rem;
}</style>
