<script>
    import {onMount, setContext} from "svelte";
    import {activePortal} from "../../../Shared/store";
</script>

<div class="auto-aip-lander__hero-picture">
    <img src="/images/mortgage-tool.png"/>
    <div class="auto-aip-lander__hero-picture__wrapper">
        <div class="min-content-width">
            <h1>Our new { $activePortal.term.toLowerCase() } quote tool</h1>
            <h3>Our tool makes it easier than ever to compare mortgage deals</h3>
        </div>
    </div>
</div>

<style>
.auto-aip-lander__hero-picture {
    position: relative;
}
.auto-aip-lander__hero-picture img {
    width: 100%;
    height: auto;
}
.auto-aip-lander__hero-picture__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.auto-aip-lander__hero-picture .min-content-width {
    max-width: 850px;
    width:90%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    color: white;
    margin: 0 auto;
    text-align: left;
    padding: 0 1rem;
    gap:1rem;
    font-size:1.2em;
}
.auto-aip-lander__hero-picture .min-content-width > * {
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 650px) {
    .auto-aip-lander__hero-picture .min-content-width {
        width: 80%;
        margin: 0;
        padding: 0 1rem;
        gap: 1rem;
        font-size: 1em;
    }
}
@media screen and (max-width: 450px) {
    .auto-aip-lander__hero-picture .min-content-width {
        width: 70%;
        font-size: 0.7em;
    }
}</style>
