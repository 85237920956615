<script>

    import {getContext, createEventDispatcher, onMount} from 'svelte';
    import { formatLoanTerm } from "../../../utils";
    import ConfirmDialog from "../../ConfirmDialog.svelte";
    import { feeTypes } from "../../../lists";

    // Dispatcher
    const dispatch = createEventDispatcher();

    // From Context
    const accessCode = getContext('accessCode');
    const portal = getContext('portal');

    // Local
    let showFees = false;

    // Passed Vars
    export let quote= null;
    export let product= null;
    export let productQuote = null;
    export let mainquote = null;
    export let primary = false;
    export let completed = false;

    // Computed Properties
    $: loanAmount = product.loanAmount || 0;
    $: term = product.rates && product.rates.length ? product.rates.reduce((a, b) => a + b.term, 0) : null;
    $: initialMonthlyPayment = product.rates && product.rates.length ? product.rates[0].monthlyRepayment : null;
    $: initialRate = product.rates && product.rates.length ? product.rates[0].rate : null;
    $: productName = product.name && product.name.length ? product.name : "N/A";
    $: aprc = product.aprc;
    $: fees = product.fees.length ? product.fees.reduce((a, b) => a + b.amount, 0) : 0;
    $: totalRepayable = product.rates.length ? product.rates.reduce((a,b) => a + (b.monthlyRepayment * b.term), 0) : 0;
    $: netLoanInterest = totalRepayable - loanAmount - fees;
    $: onlyOneAndFixed = product.rates.length === 1 && product.rates[0].type === "Fixed";

    $: repPayments = product.rates.length ? product.rates.map((rate) => {
        return `${rate.term} instalments of £${rate.monthlyRepayment.toFixed(2)} at ${rate.rate.toFixed(2)}% (${rate.type})`
    }).join(", followed by ") : '';

    $: repExample = `Borrowing £${loanAmount.toLocaleString("en-GB", {minimumFractionDigits: 0,maximumFractionDigits: 0})} over ${term} months. You will pay
    ${repPayments}. Total amount payable of £${totalRepayable.toLocaleString("en-GB", {minimumFractionDigits: 2,maximumFractionDigits: 2})}.
    This includes a lender fee of £${fees.toLocaleString("en-GB", {minimumFractionDigits: 2,maximumFractionDigits: 2})} and the
    net loan interest of £${netLoanInterest.toLocaleString("en-GB", {minimumFractionDigits: 2,maximumFractionDigits: 2})}.
    The overall cost for comparison is ${aprc}% APRC ${onlyOneAndFixed ? '' : 'Variable'}.`;

    async function proceed() {
        productQuote = product
    }

    function toggleFees(value) {
        showFees = value != null ? value : !showFees;
    }

</script>

<div class={`quote ${primary ? 'primary' : ''} ${completed ? 'completed' : ''}`}>

    <h2>Borrowing  <span class="highlight">&pound;{
        loanAmount.toLocaleString("en-GB", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0})
    }</span> over <span class="highlight">{ formatLoanTerm(term) }</span> from <span class="highlight">{ product.lender }</span></h2>
    <p>Product: { product.name }</p>

    <div class="quote__content">
        <div class="block">
            <div class="img"><img src={product.imageUrl} alt={product.lender} /></div>
        </div>
        <div class="block highlight">
            <span>Initial Monthly Payment</span>
            <h2>
                &pound;{
                initialMonthlyPayment.toLocaleString("en-GB", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }
            </h2>
        </div>
        <div class="block">
            <span>Initial Interest Rate</span>
            <h2>
                {
                    initialRate.toLocaleString("en-GB", {
                        maximumFractionDigits: 2
                    })
                }%
            </h2>
        </div>
        <div class="block">
            <span>Total Repayable</span>
            <h2>&pound;{totalRepayable.toLocaleString("en-GB", { minimumFractionDigits: 2, maximumFractionDigits: 2})}</h2>
        </div>
        <div class="block">
            <span>Fee {#if fees > 0}<button class="circle" on:click={() => toggleFees(true) }>&#x2139;</button>{/if}</span>
            <h2>&pound;{fees.toLocaleString("en-GB", { maximumFractionDigits: 2})}</h2>
        </div>
        <div class="block">
            <span>APRC</span>
            <h2>
                {
                    aprc.toLocaleString("en-GB", {
                        maximumFractionDigits: 2
                    })
                }%
            </h2>
        </div>
    </div>

    {#if mainquote.status === "quoted"}
        {#if product.potentialSaving}
            <p>Consolidating your existing credit could save you</p>
            <h2>£{(Number(product.potentialSaving)).toLocaleString("en-GB", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })} per month*
            </h2>
        {/if}
        <div class="quote__footer">
            <button class="button green fill pill" on:click={() => proceed()} >Proceed</button>
            <p class="small">{ repExample }</p>
        </div>
    {/if}

    <ConfirmDialog
            title="Fees"
            buttonConfirmText="OK"
            on:confirm={() => toggleFees(false)}
            bind:show={showFees}>
       <table class="fee__table">
           <thead>
               <tr>
                   <th>Amount</th>
                   <th>Description</th>
               </tr>
           </thead>
           <tbody>
           {#each product.fees.filter(x => x.amount > 0) as fee}
               <tr>
                   <td>&pound;{ fee.amount.toLocaleString("en-GB", { maximumFractionDigits: 2 }) }</td>
                   <td>{ feeTypes.find(x => x.value == fee.type).label }</td>
               </tr>
           {/each}
            </tbody>
       </table>
    </ConfirmDialog>

</div>

<style>

.quote {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 1rem;
    text-align: center;
}

.quote > h2,
.quote > p {
    padding:0;
    margin:0;
}

.quote__content {
    min-width: 100%;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-pack: stretch;
        -ms-flex-pack: stretch;
            justify-content: stretch;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
}

.quote__content div.img {
    max-height:100px;
    min-width: 125px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding:0.5rem 0;
}

.quote__content div.block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;;
    -webkit-box-pack: center;;
        -ms-flex-pack: center;;
            justify-content: center;
    gap: 0.5rem;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 1rem 0;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
}

.quote__content div.block h2 {
    padding:0;
    margin:0;
}

.quote__content div.block.highlight {
    text-align: center;
    background-color: #bfe5ee;
}

.quote__content button.circle {
    height:24px;
    width:24px;
    background-color: #00a854;
    border: none;
    font-size: 1rem;
    padding: 0;
    margin: 0;
    cursor: pointer;
    border-radius: 100%;
    color:white;
}

.quote__footer {
    width:100%;
    text-align: center;
}

.quote__footer .small {
    font-size:12px;
}

.quote.completed {
    padding: 1rem;
}

.quote.completed .quote__info__image {
    display:none;
}

@media (max-width: 650px) {
    .quote__content {
        grid-template-columns: repeat(2, 1fr);
    }
    .quote__content div.block h2 {
        font-size: 1.25rem;
    }
}

/* ==================== */
/* POP FEE TABLE */
/* ==================== */

.fee__table th, .fee__table td {
    padding:0.5rem;
}</style>
