<script>

    import {getContext, createEventDispatcher} from 'svelte';
    import {activePortal} from "../../../store";

    const dispatch = createEventDispatcher();

    import Callback from "../../Callback.svelte";
    import api from "../../../api";

    /* From Context */
    const accessCode = getContext('accessCode');
    const portal = getContext('portal');

    /* Passed Vars */
    export let quote;

    /* Local Vars */
    let working = false;
    let callback = {
        date: '',
        time: ''
    }
    let searchConsent = false;

    /* Functions */
    async function online() {
        working = true;
        quote = await api.autoAipUpdate(portal, accessCode, "reactivate-online");
        working = false;
        dispatch("online");
    }

    const offline = async () => {
        working = true;
        quote = await api.autoAipUpdate(portal, accessCode, "reactivate-offline", {
            callbackTime: callback.date.set(callback.time).toUTC().toISO()
        });
        working = false;
    }

</script>

<div class="container auto-aip__new">
    {#if quote.name}
        <h1>Welcome back to { $activePortal.brand.name }, {quote.name}!</h1>
    {:else}
        <h1>Welcome back to { $activePortal.brand.name }!</h1>
    {/if}
    <h2>{ $activePortal.brand.name } is one of the UK's market-leading { $activePortal.term.toLowerCase() } brokers.</h2>
    <h3>How would you like to proceed?</h3>
    <div class="auto-aip__new__options">
        <div class="auto-aip__new__info__box auto-aip__new__options__online">
            <h2>Online</h2>
            <p>In many cases you don't have to speak to us to get a new quote, tap <strong>Continue</strong> below and we will try and get you a quote online now!</p>
            {#if quote.creditSearchConsentRequired}
                <p>To proceed online we need your consent to run a soft quotation credit search. This won't affect your credit rating - you can find more information in our <a
                            href="https://{ $activePortal.brand.domain }/terms"
                            target="_blank">terms &amp; conditions</a> and <a href="https://{ $activePortal.brand.domain }/privacy"
                                                    target="_blank">privacy policy</a>.</p>
                <p>
                    <a href="#" on:click|preventDefault="{() => { searchConsent = !searchConsent}}" class={`consent ${searchConsent ? 'green' : ''}`}>
                        <span class="check">&#10004;</span><span>I accept</span>
                    </a>
                </p>
            {/if}
            <button class="button fill green pill"
                    on:click={() => online()}
                    disabled={working || (quote.creditSearchConsentRequired && !searchConsent)}>Continue
            </button>
        </div>
        <div class="auto-aip__new__info__box auto-aip__new__options__offline">
            <h2>Over the phone</h2>
            <p>If you prefer, select a good time to call and one of our award winning team will be in touch to go through a new quote with you.</p>
            <form on:submit|preventDefault={offline}>
                <Callback bind:callback={callback}/>
                <button
                        class="button green fill mt pill"
                        type="submit"
                        disabled={!callback.date || !callback.time || working}
                >Request Call
                </button>
            </form>
        </div>
    </div>
</div>


<style>


.auto-aip__new {
    width: 100%;
    margin-top: 1rem;
    padding: 1rem;
    background-color: white;
    border: 3px solid #01b57a;
    border-radius: 0.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-line-pack: stretch;
        align-content: stretch;
    text-align: center;
}


.auto-aip__new__info__box {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 1rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-line-pack: stretch;
        align-content: stretch;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 0.75rem;
}

.auto-aip__new__options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap:1rem;
    padding:1rem;
}

.auto-aip__new__options h2 {
    margin:0;
    padding:0;
}

.auto-aip__new__options form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.auto-aip__new__features {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
}

@media(max-width: 850px) {
    .auto-aip__new__options {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
}

/* -------------------------------------------------- */
/* Retarget */
/* -------------------------------------------------- */

.consent {
    font-size:1.25rem;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap:0.5rem;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0.5rem 1rem;
    text-decoration: none;
}

.consent .check {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    width:2rem;
    height:2rem;
    border: 2px solid #bfe5ee;
    border-radius: 50%;
    background-color: white;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color:white;
}

.consent.green .check {
    color: #00a854;
    border-color: #00a854;
}

.auto-aip__new__features {
    gap:1rem;
}

.auto-aip__new__features > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: left;
}

.auto-aip__new__features span {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 2px solid #01b57a;
    font-size: 1.5rem;
    font-family: "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    margin-right:1rem;
    font-weight: bold;
    aspect-ratio: 1;
    background: #01b57a;
    color:white;
}</style>
