<script>
    import { onMount } from 'svelte';
    import Slider from 'svelte-range-slider-pips';
    import { productValues} from "../../../lists";

    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();
    import { fly, fade } from 'svelte/transition';
    import { quintOut } from 'svelte/easing';

    import { activePortal, defaultPortal } from "../../../store";
    import { getContext } from 'svelte';
    import { formatLoanTerm } from "../../../utils";
    import api from "../../../api";
    import Fields from "./Fields.svelte";
    import Nav from "../../../Layouts/Elements/Nav.svelte";

    // From Context
    const accessCode = getContext('accessCode');
    const portal = getContext('portal');

    // Passed Vars
    export let quote;

    // Visible
    let show = false;

    // Quote Sliders
    let loanAmountEntered = 0;
    let loanTermEntered = 0;

    function needsToPostUpdate() {
        return loanAmountEntered !== loanAmount || loanTermEntered !== loanTerm
    }

    $: loanAmount = quote.parameters ?
        quote.parameters.amount ?
            quote.parameters.amount : (quote.customerRequestedLoanAmount ? quote.customerRequestedLoanAmount : 10000)
        :
        (quote.customerRequestedLoanAmount ? quote.customerRequestedLoanAmount : 10000);

    $: loanTerm = quote.parameters ? (quote.parameters.term ? quote.parameters.term : quote.customerRequestedLoanTerm) : quote.customerRequestedLoanTerm;

    $: minLoanAmountFound = quote.quotes.length ? quote.quotes.map(a =>
        a.products.map(b =>
            b.loanAmount
        )).flat().reduce((a, b) =>
        a < b ? a : b
    ) : loanAmount;

    $: minLoanTermFound = quote.quotes.length ? quote.quotes.map(a =>
        a.products.map(b =>
            b.rates.map(r => r.term).reduce((a, b) => a + b)
        )).flat().reduce((a, b) =>
        a < b ? a : b
    ) : loanTerm;

    $: isSecondCharge = portal === "second-charge";

    let selectedPortal = defaultPortal.standard;
    activePortal.subscribe(c => {
        selectedPortal = c ? c : defaultPortal.standard;
    });

    $: allowedLoanAmount = portal === "second-charge" ? { min: 10000, max: 1000000 } : { min: 50000, max: 3000000 };
    $: allowedLoanTerm = Array.from({length: (420 - 24) / 12 + 1}, (v, k) => k * 12 + 24)
        .map(a => {
            return {
                value: a,
                label: formatLoanTerm(a)
            }
        });

    $: valid =
        loanAmountEntered >= allowedLoanAmount.min &&
        loanAmountEntered <= allowedLoanAmount.max &&
        loanTermEntered >= allowedLoanTerm[0].value &&
        loanTermEntered <= allowedLoanTerm[allowedLoanTerm.length - 1].value;

    // Mount
    onMount(() => {
        loanAmountEntered = loanAmount;
        loanTermEntered = loanTerm;
    });

</script>

<div class="edit-details__wrapper" class:second-charge={isSecondCharge}>

    <button class="button fill mt" class:orange={!isSecondCharge} on:click={() => show = !show }>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 72.126 72.127">
            <path id="Path_47" data-name="Path 47" d="M1465.464,589.489a5.992,5.992,0,0,0-4.249,1.761l-4.25,4.25,15.027,15.026,4.249-4.249a6.011,6.011,0,0,0,0-8.5l-6.527-6.527A5.994,5.994,0,0,0,1465.464,589.489Zm-14.51,12.021-36.063,36.064,4.508,1.5,1.5,7.513,7.513,1.5,1.5,4.507,36.064-36.063Zm-41.616,48.543-3.351,9.485a1.508,1.508,0,0,0,1.391,2.078,1.525,1.525,0,0,0,.546-.1l9.514-3.364Z" transform="translate(-1405.875 -589.489)" fill="#fff"/>
        </svg>
        Edit
    </button>

    {#if show}
        <div class="edit-details__background" on:click|self={() => show = !show} transition:fade="{{delay: 100}}">
            <div class="edit-details" transition:fly="{{ delay: 100, duration: 300, x: 250, easing: quintOut }}">

                <header>
                    <div class="logo">
                        <img src="/{ selectedPortal.brand.logo }" alt="{ selectedPortal.brand.name }" />
                    </div>
                </header>

                <div class="edit-details__header">
                    <a href="#"  on:click={() => show = !show }>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 66 66">
                            <circle cx="33" cy="33" r="33" fill="#fff"/>
                            <g transform="translate(-6564 -205)">
                                <line id="Line_16" x2="27" y2="26" transform="translate(6583.084 225.935)" fill="none" stroke="#ff4900" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                <line id="Line_17" x1="27" y2="26" transform="translate(6583.084 225.935)" fill="none" stroke="#ff4900" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                            </g>
                        </svg>
                    </a>
                    <div></div>
                </div>

                <div class="edit-details__body">
                    <div class="edit-details__details__section">

                        <h4>Borrowing</h4>
                        <Fields
                            borderless
                            fieldName="loanAmountEntered"
                            dataType="number"
                            required={true}
                            bind:value={loanAmountEntered}
                        />
                        {#if isNaN(loanAmountEntered) || loanAmountEntered < allowedLoanAmount.min}
                            <div class="error">
                                <p>Minimum amount is £{allowedLoanAmount.min.toLocaleString("en-GB", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                })}</p>
                            </div>
                        {:else if loanAmountEntered > allowedLoanAmount.max}
                            <div class="error">
                                <p>Maximum amount is £{allowedLoanAmount.max.toLocaleString("en-GB", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                })}</p>
                            </div>
                        {/if}

                        <h4>{ $activePortal.term } Term</h4>
                        <Fields
                            borderless
                            fieldName="loanTermEntered"
                            dataType="dropdown"
                            options={allowedLoanTerm}
                            initialOption="{false}"
                            required={true}
                            hideAddon=""
                            bind:value={loanTermEntered}
                        />

                        <div class="block mt">
                            <button class="button fill green pill mt m-auto" disabled={!valid} on:click|self={() => {
                                if (!valid) return;
                                show = !show;
                                dispatch("updateQuote", {
                                    loanAmount: loanAmountEntered,
                                    loanTerm:loanTermEntered,
                                    postUpdate: needsToPostUpdate()
                                });
                            }}>Update results</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    {/if}

</div>

<style>

.edit-details__wrapper {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}

.edit-details__wrapper > button {
    padding:0.75rem 1rem;
    width:100%;
}

.edit-details__background {
    z-index: 2000;
    position: fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color: rgba(0, 0, 0, 0.25);
}

.edit-details {
    z-index: 2001;
    position: fixed;
    top:0;
    bottom:0;
    right:0;
    width:30vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align:stretch;
        -ms-flex-align:stretch;
            align-items:stretch;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    overflow-y:scroll;
    background: rgb(107,179,198);
    background:
            linear-gradient(164deg,
            rgba(107,179,198,1) 0%,
            rgba(250,250,250,1) 45%,
            rgba(250,250,250,1) 100%);

    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

@media(max-width: 1100px) {
    .edit-details {
        width: 40vw;
    }
}

@media(max-width: 850px) {
    .edit-details {
        width: 50vw;
    }
}

@media(max-width: 650px) {
    .edit-details {
        width: auto;
        left:0;
        border-radius: 0;
    }
}

.edit-details__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 0.5rem 1rem;
    color:white;
    background-color: #FF4900;
}

.edit-details__header a {
    text-decoration: none;
    color:white;
}
.edit-details__header a:hover{
    text-decoration: underline;
}

.edit-details__body {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    padding:1rem;
}

.edit-details h4 {
    font-size:1.2rem;
    margin-bottom:0.5rem;
    font-weight: bold;
}

.edit-details__details__section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width:100%;
}

.edit-details__details__section > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.edit-details__details__section > div.block {
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    justify-items: left;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.edit-details__details__section div.error p {
    color: #ff0000;
    font-size: 1rem;
    margin: 0.25rem 0 0 0;
}

.edit-details__details__section .checkboxes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top:1rem;
    gap: 0.75rem;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.edit-details__details__section .checkboxes label input {
    display: none;
}

.edit-details__details__section .checkboxes label span {
    display: block;
    border-radius: 0.3rem;
    background-color: white;
    padding:1rem 1rem;
    cursor:pointer;
    -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
            box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
}

.edit-details__details__section .checkboxes label:hover span {
    background-color: rgba(1,1,1,0.05);
}

.edit-details__details__section .checkboxes label input:checked + span {
    background-color: #00a854;
    border-color: #01b57a;
    color:white;
}

.edit-details__details__section .checkboxes label:hover input:checked + span {
    background-color: #029b4f;
}

.edit-details__details__section div > span {
    padding:0;
    margin:0;
    font-size:1rem;
    font-weight:normal;
    text-transform: uppercase;
    color: #999999;
    -webkit-box-flex:1;
        -ms-flex-positive:1;
            flex-grow:1;
    text-align: left;
    max-width:50%;
}

.edit-details__details__section div > p {
    padding:0;
    margin:0;
    font-size:1.2rem;
    padding-bottom: 0.5rem;
}

.edit-details__details__section .mt {
    margin-top:0.5rem;
}
@media(max-width:  1200px) {
    .edit-details__details {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        gap:normal;
    }
}

@media(max-width: 500px) {
    .edit-details__details__section div > span {
        max-width:100%;
    }

}

/* ==================== */
/* SECOND CHARGE COLOUR CHANGES */

.second-charge .edit-details__header {
    background-color: #00a854;
}

.second-charge .edit-details__header svg line {
    stroke: #00a854;
}

.second-charge .edit-details__details__section .checkboxes label input:checked + span {
    background-color: #00a854;
    border-color: #01b57a;
}

.second-charge .edit-details__details__section .checkboxes label:hover input:checked + span {
    background-color: #029b4f;
}

/* ==================== */
/* HEADER SECTION */

header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding:0.5rem 1rem;
    background-color: #ffffff;
    border-bottom: 1px solid #cccccc;
    position: relative;
    z-index: 10;
}

header > .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 1em;
}

header > .container > div {
    text-align: right;
}

header > .container > div > h3 {
    margin: 0;
}

header > .container > div > p {
    margin: 0;
}

header img {
    max-height: 50px;
}

header .logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width:100%;
}</style>
