<script>

    export let title = "";

    let classes = '';
    export { classes as class };

</script>

<div class={`sidebar ${classes || ''}`}>
    <h1 class="sidebar__title">{title}</h1>
    <div class="sidebar__wrapper">
        <div class="sidebar__content">
            <slot name="sidebar"></slot>
        </div>
        <div class="sidebar__main">
            <div class="sidebar__main__card">
                <div class="sidebar__main__card__body">
                    {#if title.length > 0}<h1 class="text-center">{title}</h1>{/if}
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
    <slot name="footer"></slot>
</div>

<style>

:global(*[slot='sidebar']) {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    z-index: 0;
}

.sidebar__title {
    display: none;
}

.sidebar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    min-width: 100%;
    min-height: 100%;
    background: rgb(107,179,198);
    background:
            linear-gradient(164deg,
            rgba(107,179,198,1) 0%,
            rgba(250,250,250,1) 35%,
            rgba(250,250,250,1) 70%,
            rgba(107,179,198,1) 100%);

}

.sidebar__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    min-width: 100%;
    min-height: 100%;
}

.sidebar__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    background:#f2f2f2;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    min-width: 15%;
}

.sidebar__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}

.sidebar__main__card {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    background:white;
    margin: 4rem 10% 4rem -4rem;
    border-radius:0.5rem;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
}

.text-center {
    text-align: center;
}

@media(max-width: 850px) {

    :global(*[slot='sidebar']) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .sidebar {
        background:
                linear-gradient(164deg,
                rgba(107,179,198,1) 0%,
                rgba(250,250,250,1) 20%,
                rgba(250,250,250,1) 70%,
                rgba(107,179,198,1) 100%);
    }

    .sidebar__wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .sidebar__content {
        border-radius: 0 0 1rem 1rem;
        margin: 0rem 2rem 0 2rem;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        z-index:50;
    }

    .sidebar__main__card {
        margin: -2rem 2rem 2rem 2rem;
        padding: 2rem 0 0 0;
    }

    .sidebar__main__card__body {
        margin: 0 1rem 1rem 1rem;
        padding: 0 1rem 1rem 1rem;
    }

}

@media(max-width: 550px) {

    .sidebar__content {
        border-radius: 0 0 1rem 1rem;
        margin: 0rem 1rem 0 1rem;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        z-index:50;
    }

    .sidebar__main__card {
        margin: -1rem 1rem 1rem 1rem;
        padding: 1rem 0 0 0;
    }

    .sidebar__main__card__body {
        margin: 0;
    }

}</style>