<script>
    import { onMount } from 'svelte';
    import Slider from 'svelte-range-slider-pips';
    import { productValues} from "../../../lists";

    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();
    import { fly, fade } from 'svelte/transition';
    import { quintOut } from 'svelte/easing';

    import { activePortal, defaultPortal } from "../../../store";
    import { getContext } from 'svelte';
    import { formatLoanTerm } from "../../../utils";
    import api from "../../../api";
    import Fields from "./Fields.svelte";
    import Nav from "../../../Layouts/Elements/Nav.svelte";

    // From Context
    const accessCode = getContext('accessCode');
    const portal = getContext('portal');

    // Passed Vars
    export let quote;
    export let filters;
    export let sort;

    // Visible
    let show = false;

    // Quote Sliders
    let loanAmountSlider = [0];
    let loanTermSlider = [0];

    let loanAmountIndex = [0];
    let loanTermIndex = [0];

    // Initial Term Filter
    let productFilter = filters != null ? filters : [];
    let sorting = sort != null ? sort : {
        by: 'monthly',
        descending: false
    };

    // Funcs
    function createRange(min, max, step) {
        let range = [];
        for (let i = min; i <= max; i += step) {
            range.push(i);
        }
        return range;
    }

    $: loanAmount = quote.parameters ?
        quote.parameters.amount ?
            quote.parameters.amount : (quote.customerRequestedLoanAmount ? quote.customerRequestedLoanAmount : 10000)
        :
        (quote.customerRequestedLoanAmount ? quote.customerRequestedLoanAmount : 10000);

    $: loanTerm = quote.parameters ? (quote.parameters.term ? quote.parameters.term : quote.customerRequestedLoanTerm) : quote.customerRequestedLoanTerm;

    $: minLoanAmountFound = quote.quotes.length ? quote.quotes.map(a =>
        a.products.map(b =>
            b.loanAmount
        )).flat().reduce((a, b) =>
        a < b ? a : b
    ) : loanAmount;

    $: minLoanTermFound = quote.quotes.length ? quote.quotes.map(a =>
        a.products.map(b =>
            b.rates.map(r => r.term).reduce((a, b) => a + b)
        )).flat().reduce((a, b) =>
        a < b ? a : b
    ) : loanTerm;

    $: isSecondCharge = portal === "second-charge";

    let selectedPortal = defaultPortal.standard;
    activePortal.subscribe(c => {
        selectedPortal = c ? c : defaultPortal.standard;
    });

    function resetFilters() {
        productFilter = [];
        sorting = {
            by: 'monthly',
            descending: false
        };
    }

</script>

<div class="filter__wrapper" class:second-charge={isSecondCharge}>

    <button class="button fill mt" class:orange={!isSecondCharge} on:click={() => show = !show }>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 60.445 59.455">
            <g id="Group_56" data-name="Group 56" transform="translate(-1981.779 -595.826)">
                <line id="Line_7" data-name="Line 7" x2="55.491" transform="translate(1984.256 606.726)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.955"/>
                <line id="Line_8" data-name="Line 8" x2="55.491" transform="translate(1984.256 625.057)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.955"/>
                <line id="Line_9" data-name="Line 9" x2="55.491" transform="translate(1984.256 643.885)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.955"/>
                <line id="Line_10" data-name="Line 10" y2="16.845" transform="translate(2029.343 598.303)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.955"/>
                <line id="Line_11" data-name="Line 11" y2="16.845" transform="translate(1999.615 617.13)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.955"/>
                <line id="Line_12" data-name="Line 12" y2="16.845" transform="translate(2018.443 635.957)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.955"/>
            </g>
        </svg>
        Filter</button>

    {#if show}
        <div class="filter__background" on:click|self={() => show = !show} transition:fade="{{delay: 100}}">
            <div class="filter" transition:fly="{{ delay: 100, duration: 300, x: 250, easing: quintOut }}">

                <header>
                    <div class="logo">
                        <img src="/{ selectedPortal.brand.logo }" alt="{ selectedPortal.brand.name }" />
                    </div>
                </header>

                <div class="filter__header">
                    <a href="#"  on:click={() => show = !show }>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 66 66">
                            <circle id="Ellipse_18" cx="33" cy="33" r="33" fill="#fff"/>
                            <g id="Group_96" transform="translate(-6564 -205)">
                                <line id="Line_16" x2="27" y2="26" transform="translate(6583.084 225.935)" fill="none" stroke="#ff4900" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                <line id="Line_17" x1="27" y2="26" transform="translate(6583.084 225.935)" fill="none" stroke="#ff4900" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                            </g>
                        </svg>
                    </a>
                    <a href="#" on:click={() => resetFilters() }>
                        Reset Filters
                    </a>
                </div>
                <div class="filter__body">
                    <div class="filter__details__section">

                        <h4>Sort By</h4>
                        <Fields
                                borderless
                                fieldName="by"
                                dataType="dropdown"
                                required={true}
                                initialOption={false}
                                options={[
                                    { label: "Initial Monthly Payment", value: "monthly" },
                                    { label: "Intial Interest Rate", value: "rate" },
                                    { label: "Fee", value: "fee" },
                                    { label: "APRC", value: "aprc"},
                                    { label: "Total Repayable", value: "total"}
                                ]}
                                bind:value={sorting.by}
                        />

                        <h4>Ordered</h4>
                        <Fields
                                borderless
                                fieldName="descending"
                                dataType="dropdown"
                                required={true}
                                initialOption={false}
                                options={[
                                    { label: "Ascending", value: false },
                                    { label: "Descending", value: true }
                                ]}
                                bind:value={sorting.descending}
                        />


                        <h4>Mortgage Type</h4>
                        <div class="block">
                            <div class="checkboxes">
                                {#each productValues as productValue}
                                        <label for={productValue.value}>
                                        <input type="checkbox"
                                               value={productValue.value}
                                               bind:group={productFilter}
                                               id={productValue.value} />
                                            <span>{ productValue.description }</span>
                                        </label>
                                {/each}
                            </div>
                        </div>


                        <div class="block mt">
                            <button class="button fill green pill mt m-auto" on:click|self={() => {
                                show = !show;
                                dispatch("updateQuote", {
                                    postUpdate: false,
                                    filters: productFilter,
                                    sort: sorting
                                });
                            }}>Update results</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    {/if}

</div>

<style>

.filter__wrapper {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}

.filter__wrapper > button {
    padding:0.75rem 1rem;
    width:100%;
}

.filter__background {
    z-index: 2000;
    position: fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color: rgba(0, 0, 0, 0.25);
}

.filter {
    z-index: 2001;
    position: fixed;
    top:0;
    bottom:0;
    right:0;
    width:30vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align:stretch;
        -ms-flex-align:stretch;
            align-items:stretch;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    overflow-y:scroll;
    background: rgb(107,179,198);
    background:
            linear-gradient(164deg,
            rgba(107,179,198,1) 0%,
            rgba(250,250,250,1) 45%,
            rgba(250,250,250,1) 100%);
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

@media(max-width: 1100px) {
    .filter {
        width: 40vw;
    }
}

@media(max-width: 850px) {
    .filter {
        width: 50vw;
    }
}

@media(max-width: 650px) {
    .filter {
        width: auto;
        left:0;
        border-radius: 0;
    }
}

.filter__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 0.5rem 1rem;
    color:white;
    background-color: #FF4900;
}

.filter__header a {
    text-decoration: none;
    color:white;
}
.filter__header a:hover{
    text-decoration: underline;
}

.filter__body {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    padding:1rem;
}

.filter h4 {
    font-size:1.2rem;
    margin-bottom:0.5rem;
    font-weight: bold;
}

.filter__details__section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width:100%;
}

.filter__details__section > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.filter__details__section > div.block {
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    justify-items: left;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.filter__details__section .checkboxes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top:1rem;
    gap: 0.75rem;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.filter__details__section .checkboxes label input {
    display: none;
}

.filter__details__section .checkboxes label span {
    display: block;
    border-radius: 0.3rem;
    background-color: white;
    padding:1rem 1rem;
    cursor:pointer;
    -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
            box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
}

.filter__details__section .checkboxes label:hover span {
    background-color: rgba(1,1,1,0.05);
}

.filter__details__section .checkboxes label input:checked + span {
    background-color: #FF4900;
    border-color: #ff540f;
    color:white;
}

.filter__details__section .checkboxes label:hover input:checked + span {
    background-color: #ea3d00;
}

.filter__details__section div > span {
    padding:0;
    margin:0;
    font-size:1rem;
    font-weight:normal;
    text-transform: uppercase;
    color: #999999;
    -webkit-box-flex:1;
        -ms-flex-positive:1;
            flex-grow:1;
    text-align: left;
    max-width:50%;
}

.filter__details__section div > p {
    padding:0;
    margin:0;
    font-size:1.2rem;
    padding-bottom: 0.5rem;
}

.filter__details__section .mt {
    margin-top:0.5rem;
}
@media(max-width:  1200px) {
    .filter__details {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        gap:normal;
    }
}

@media(max-width: 500px) {
    .filter__details__section div > span {
        max-width:100%;
    }

}

/* ==================== */
/* SECOND CHARGE COLOUR CHANGES */

.second-charge .filter__header {
    background-color: #00a854;
}

.second-charge .filter__header svg line {
    stroke: #00a854;
}

.second-charge .filter__details__section .checkboxes label input:checked + span {
    background-color: #00a854;
    border-color: #01b57a;
}

.second-charge .filter__details__section .checkboxes label:hover input:checked + span {
    background-color: #029b4f;
}

/* ==================== */
/* HEADER SECTION */

header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding:0.5rem 1rem;
    background-color: #ffffff;
    border-bottom: 1px solid #cccccc;
    position: relative;
    z-index: 10;
}

header > .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 1em;
}

header > .container > div {
    text-align: right;
}

header > .container > div > h3 {
    margin: 0;
}

header > .container > div > p {
    margin: 0;
}

header img {
    max-height: 50px;
}

header .logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width:100%;
}</style>
