<script>
    import NoSidebar from '../../Shared/Layouts/NoSidebar.svelte'
  import recaptcha from "../../Shared/recaptcha";
  import api from "../../Shared/api";

  export let code;

  let password = "", error = "", working = false, success = false, expired = false;

  function resetPassword() {
    error = "";
    if (!password) {
      error = "Please enter your new password"
      return;
    }
    if (password.length < 8) {
      error = "Please choose a password with at least 8 characters"
      return;
    }
    working = true;

    recaptcha.action("complete_reset_password", async (token) => {
      const result = await api.completeReset(code, password, token);

      working = false;
      success = result.success;

      if (!result.success && result.reason === "tokenExpired") {
        expired = true;
      } else if (!result.success) {
        error = result.error || "Unable to reset your password right now, please try again later.";
        return;
      }
    });
  }

  function handleEnter(e) {
    if (!working && e.key === "Enter") {
      resetPassword();
    }
  }
</script>

<NoSidebar title="Reset your Account">
    <div class="reset">
        {#if success}
            <p>Your account was successfully reset {success}. You can now <a href="/sign-in">sign in</a> with your new password.
            </p>
        {:else}
            <p>Please choose a new password for your account:</p>
            <div class="form">
                <label for="password">Password:</label>
                <input id="password" type="password" bind:value={password} on:keyup={handleEnter}/>
            </div>
            {#if expired}
                <span class="error">Your account reset link has expired. Please <a href="/reset">reset your account</a> again to receive a new link.</span>
            {:else if error}
                <span class="error">{error}</span>
            {/if}

            <button class="button" disabled={working}
                    on:click={resetPassword}>{ working ? "Please Wait..." : "Reset Password" }</button>
        {/if}
    </div>
</NoSidebar>


<style>

.reset {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.form {
    margin-bottom:1rem;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1em;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.error {
    margin-bottom:1rem;
    color:red;
}

.links {
    margin-top:1rem;
}</style>

