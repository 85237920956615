<script>
  import {activeCase } from "../../Shared/store";
  import {formatLoanTerm} from "../../Shared/utils";
  import {loanPurposes} from "../../Shared/lists";

  $: isNotProtect = $activeCase.portal.api !== 'protect';

  $: quoted = $activeCase && $activeCase.product && isNotProtect;
  $: title = quoted ? `${$activeCase.portal.term} Approved in Principle` : "We're working on your quote";
  $: subTitle = quoted ? `Great news, you have been accepted for a ${$activeCase.portal.term.toLowerCase()} with ${lender}!` : `Please check back later for updates. In the mean time, if you need us you can always call us on ${$activeCase.portal.brand.telephone}.`;
  $: lender = quoted && $activeCase.product.lender ? $activeCase.product.lender : $activeCase.portal.brand.name;
  $: loanTerm = quoted && $activeCase.product.rates ? $activeCase.product.rates.reduce((a, b) => a + b.term, 0) : null;
  $: initialMonthlyPayment = quoted && $activeCase.product.rates && $activeCase.product.rates.length ? $activeCase.product.rates[0].monthlyRepayment : null;
  $: initialRate = quoted && $activeCase.product.rates && $activeCase.product.rates.length ? $activeCase.product.rates[0].rate : null;
  $: loanPurpose = $activeCase.loanPurposes.length ? loanPurposes[$activeCase.loanPurposes[0]] ? loanPurposes[$activeCase.loanPurposes[0]] : "Other" : "Other";

</script>

<div class="quoted">
    <div class="container">
        <h1>{title}</h1>
        <h3>{subTitle}</h3>
    </div>
    {#if quoted}
        <div class="terms">
            <div class="container">
                <div>
                    <span>Borrowing</span>
                    <p>&pound;{ $activeCase.product.loanAmount.toLocaleString("en-GB") }</p>
                </div>
                <div>
                    <span>Purpose</span>
                    <p class="capitalize">{ loanPurpose }</p>
                </div>
                <div>
                    <span>Term</span>
                    <p>{ formatLoanTerm(loanTerm) }</p>
                </div>
            </div>
        </div>
        <div class="container">
            <h2>Summary of your Agreement in Principle:</h2>
        </div>
        <div class="terms">
            <div class="container">
                {#if lender !== "Believe Loans"}
                    <div>
                        <span>Lender</span>
                        <p>{ lender }</p>
                    </div>
                {/if}
                <div>
                    <span>Initial Monthly Payment</span>
                    <p>
                        &pound;{
                      initialMonthlyPayment.toLocaleString("en-GB", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }
                    </p>
                </div>
                <div>
                    <span>Initial Interest Rate</span>
                    <p>
                        {
                          initialRate.toLocaleString("en-GB", {
                            maximumFractionDigits: 2
                          })
                        }%
                    </p>
                </div>
                <div>
                    <span>Provisionally Accepted by Lender</span>
                    <p>Yes</p>
                </div>
            </div>
        </div>
        <div class="container next-steps">
            <h2>Next Steps</h2>
            <p>
                If you would like us to proceed with this quote, let us know and return the requested documentation.
            </p>
            <p>
                We will then take it from there for you. You can call us on <strong>{ $activeCase.portal.brand.telephone }</strong>.
            </p>
            <a class="button call" href={`tel:${$activeCase.portal.brand.telephone.replace(/ /g,'')}`}>Call Us</a>
        </div>
    {/if}
</div>

<style>

.terms .container div {
    background-color: #bfe5ee;
    border-radius: 0.5rem;
}

.terms .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    gap: 0.5rem;
}

.terms .container > div {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 0.5em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;

}
.terms .container > div > span {
    font-weight: bold;
    color: hsl(190, 64%, 30%);
    display: block;
    margin: 1em 0;
}

.terms .container > div > p {
    color: #124853;
    font-size: 1.75em;
}

.call {
    margin-top: 1rem;
}

@media screen and (max-width: 650px) {
    .terms .container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .terms .container > div > span,
    .terms .container > div > p {
        display: block;
        margin:0;
        padding:0.5rem;
    }
}</style>