<script>
    import {onMount, setContext} from "svelte";

    // General
    import Sidebar from "../../Shared/Layouts/Sidebar.svelte";
    import Stepper from "../../Shared/Layouts/Elements/Stepper.svelte";
    import TrustBox from "../../Shared/Components/TrustBox.svelte";
    import LoadingIndicator from "../../Shared/Components/LoadingIndicator.svelte";


    import Step1 from "../../Shared/Components/AIP/Step1.svelte";
    import Step2 from "../../Shared/Components/AIP/Step2.svelte";
    import Step3 from "../../Shared/Components/AIP/Step3.svelte";

    import api from "../../Shared/api";

    import Upload from "../../Shared/Components/Upload.svelte";
    import TypeForm from "../../Shared/Components/TypeForm.svelte";

    import {activePortal} from "../../Shared/store";

    import {portals} from "../../Shared/portals";

    import { aipAccessCode } from "../../Shared/utils";

    export let accessCode;
    export let portal;

    let quote;

    // Set context for children (use getContext on children to get values)
    setContext('accessCode', accessCode);
    setContext('portal', portal);

    // Set Active Portal
    activePortal.set(portals.all.find(x => x.api === portal));

    onMount(async () => {
        const result = await api.aipStatus(portal, accessCode);
        if (result.success) {
            quote = result;
        }
        window.addEventListener('popstate', statePopped)
        return () => window.removeEventListener('popstate', statePopped)
    });
    
    const statePopped = () => {
        upload.show = false;
        showExpenditure = false;
    }

    let upload = {
        show: false,
        description: "",
        type: ""
    };

    let step = 0;
    let steps = ['Approved in Principle','Income/Expenditure', 'Completed'];

    let showExpenditure = false;

    function next() {
        step++;
    }

    function showUpload(e) {
        upload = {
            description: e.detail.description,
            type: e.detail.type,
            show: true
        };
    }

    function uploadBack() {
        window.history.back();
    }
    
    function uploadComplete() {
        upload.description === "Income"
          ? quote.proofOfIncomeProvided = true
          : quote.identityDocumentProvided = true;
        upload.show = false;
    }
    
    async function expenditureComplete(e) {
        await api.expenditureResult(e.detail);
        quote.expenditureQuestionnaireCompleted = true;
        showExpenditure = false;
    }
    
    function skipIdUpload() {
        api.skipIdUpload();
        quote.identityDocumentProvided = true;
    }

</script>

<Sidebar class="sidebar__container">

    <div slot="sidebar">
        <Stepper
                stepIndex={step}
                steps={steps}
        />
    </div>

    {#if !quote}
        <LoadingIndicator description="Loading your quote, please wait..." />
    {:else}
        <div class="aip">
            {#if upload.show}
                <div class="container">
                <Upload aip
                        bind:uploadName={upload.description}
                        bind:uploadType={upload.type}
                        on:back={uploadBack}
                        on:uploaded={uploadComplete}
                />
                </div>
            {:else if showExpenditure}
                <TypeForm
                        formId="hqVJ3Mdy"
                        accessCode="{aipAccessCode()}"
                        on:back={uploadBack}
                        on:completed={expenditureComplete} />
            {:else}
                {#if step === 0}
                    <Step1 bind:quote={quote}
                           on:next={() => next()}
                    />
                {:else if step === 1}
                    <Step2 bind:quote={quote}
                           on:upload={(e) => showUpload(e)}
                           on:expenditure={() => showExpenditure = true}
                           on:skipId={skipIdUpload}
                           on:next={() => next()}
                    />
                {:else if step >= 2}
                    <Step3 bind:quote={quote}
                           on:next={() => next()} />
                {/if}
            {/if}

        </div>
    {/if}

    <div slot="footer" class="footer">
        <TrustBox cssClass="padding-top"
                  templateId={ $activePortal.trustPilot.templateId }
                  businessUnitId={ $activePortal.trustPilot.businessUnitId }
                  domain={ $activePortal.brand.domain }
        />
        <footer>
            <p>
                This Approved in Principle Quote does not constitute a mortgage offer and does not entitle its recipients to a mortgage advance from the lender. Exact figures and interest rates will be fully explained in your Mortgage Illustration, please ensure you fully read through this once you receive it. Any offer of a mortgage is subject to the prevailing terms and conditions and prior to the lenders full underwriting assessment.
            </p>
            <p>
                <strong>Your home may be repossessed if you do not keep up repayments on your mortgage.</strong>
            </p>
        </footer>
    </div>

</Sidebar>

<style>

.aip {
    text-align: center;
    width:80%;
    margin: 0 auto;
}

footer, .footer {
    background-color: #ffffff;
    padding: 1em;
    border-top: 1px solid #cccccc;
}

@media(max-width: 850px) {
    .aip {
        width:100%;
    }
}</style>