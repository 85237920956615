<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();
    import api from '../../api';
    import Callback from "../Callback.svelte";

    export let quote = null;
    let working = false;
    let complete = false;
    let callback = {
        date: '',
        time: ''
    }

    $: title = `Thank You${quote.name ? `, {quote.name}!` : ``}`;

    const handleSubmit = async () => {
        working = true;
        await api.requestCallback(callback.date.set(callback.time).toUTC().toISO())
        complete = true;
        working = false;
        next();
    }

    function next() {
        dispatch('next');
    }

</script>

<div class="step3">
    <div class="container">
        <h1>{title}</h1>
        <h2>What happens next?</h2>
    </div>
    <div class="thanks">
        <div class="container">
            {#if !complete}
                <div class="step3__callback">
                    <p>To finalise your application, is there a good time to call?</p>
                    <p>Let us know here:</p>
                    <form on:submit|preventDefault={handleSubmit}>
                        <Callback bind:callback={callback}/>
                        <button
                                class="button green fill"
                                type="submit"
                                disabled={!callback.date || !callback.time || working}
                        >Request Call
                        </button>
                    </form>
                </div>
            {:else}
                <div class="step3__completed">
                    <svg
                            width="48"
                            height="48"
                            xmlns="http://www.w3.org/2000/svg"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            fill="green"
                            viewBox="0 0 24 24"
                    ><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                    </svg>
                    <p>Thank you, we will call you at {callback.time.hour}:{callback.time.minute} on {callback.date.weekdayLong} {callback.date.day} {callback.date.monthLong}.</p>
                </div>
            {/if}
        </div>
    </div>
</div>

<style>

.step3 h1,
.step3 p {
    margin-top:0;
}

.step3__callback {
    margin:0 auto;
    width:50%;

    border: 2px solid #bfe5ee;
    border-radius: 0.75rem;
    padding: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-line-pack: stretch;
        align-content: stretch;
}

.step3__callback .button {
    margin-top:1rem;
}

.step3__callback h2 {
    margin-top:0;
}

@media(max-width: 1400px) {
    .step3__callback {
        width: 80%;
    }
}

.step3__completed {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}</style>