<script>
    import SidebarNavigation from "../Shared/Layouts/SidebarNavigation.svelte";
    import {get} from "svelte/store";
    import {activeCase, activeCaseData} from "../Shared/store";
    import Document from "../Shared/Components/Document.svelte";
    import Upload from "../Shared/Components/Upload.svelte";

    $: fromUs = $activeCaseData ? $activeCaseData.documents.outbound : [];
    $: fromYou = $activeCaseData ? $activeCaseData.documents.inbound : [];
</script>

<SidebarNavigation title="Documents">

    <div class="documents__wrapper">
        <div class="container">
            <p>
                Below you will find any documents we have sent you related to your { $activeCase.portal.term.toLowerCase() } and a history of the documents you have provided to us.
            </p>
                <p>
                    If you need to send us a document, that isn't on your to-do list, from your device such as a photo you've taken or a document you've scanned, you can do so below.
                </p>
                <Upload class="upload" on:uploaded={(e) => {
                    let payload = e;
                    while ('detail' in payload) {
                        payload = payload.detail;
                    }
                    const files = payload.completed.map(c => c.doc);
                    let data = get(activeCaseData);
                    data.documents.inbound = (data.documents.inbound === null ? files : [...data.documents.inbound, ...files]);
                    activeCaseData.set(data);
                }} />

            <h2>From Us</h2>
            {#if !fromUs }
                <p>Loading...</p>
            {:else}
                {#if fromUs.length}
                    <div class="documents">
                        {#each fromUs as doc, index (`${doc.fileName}_${index}`)}
                            <Document doc={doc}/>
                        {/each}
                    </div>
                {:else}
                    <p>We haven't sent you any documents.</p>
                {/if}
            {/if}

            <h2>From You</h2>
            {#if !fromYou}
                <p>Loading...</p>
            {:else}
                {#if fromYou.length}
                    <div class="documents">
                    {#each fromYou as doc, index (`${doc.fileName}_${index}`)}
                        <Document doc={doc}/>
                    {/each}
                    </div>
                {:else}
                    <p>We haven't received any documents from you.</p>
                {/if}
            {/if}
        </div>
    </div>

</SidebarNavigation>

<style>

.documents__wrapper {
    padding:1rem;
}

.documents, .packs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
}
@media screen and (max-width: 1236px) {
    .documents {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 850px) {
    .documents__wrapper {
        padding:0;
    }
    .documents {
        grid-template-columns: 1fr;
    }
}</style>
