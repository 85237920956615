<script>

    export let stepIndex = 0;
    export let steps = [];

</script>

<div class="stepper__steps-list">
    {#if steps.length}
        {#each steps as step, index}
            <div class="stepper__steps-item">
                <div class="stepper__steps-item-wrapper">
                    <!--{#if index == 0}-->
                    <!--    {#if stepIndex < steps.length}-->
                    <!--        <h3 class="stepper__steps-title">Step {stepIndex+1} of {steps.length}</h3>-->
                    <!--    {:else}-->
                    <!--        <h3 class="stepper__steps-title">Step {steps.length} of {steps.length}</h3>-->
                    <!--    {/if}-->
                    <!--{/if}-->
                    <div class="stepper__steps-item-title">{step}</div>
                </div>
                {#if index < stepIndex}
                    <div class="stepper__steps-item-circle stepper__steps-item-complete">&#10004;</div>
                {:else}
                    <div class="stepper__steps-item-circle stepper__steps-item-number">{(index+1).toString()}</div>
                {/if}
            </div>
            {#if index < steps.length - 1}
                <div class="stepper__steps-item-spacer"></div>
            {/if}
        {/each}
    {/if}
</div>
<div class="stepper__steps-mobile">
    <!--{#if steps.length}-->
    <!--    {#if stepIndex < steps.length}-->
    <!--        <h3 class="stepper__steps-title">Step {stepIndex+1} of {steps.length}</h3>-->
    <!--    {:else}-->
    <!--        <h3 className="stepper__steps-title">Step  {steps.length} of {steps.length}</h3>-->
    <!--    {/if}-->
    <!--{/if}-->
    <h4>{steps[stepIndex] ? steps[stepIndex] : ""}</h4>
</div>


<style>

.stepper__steps-mobile {
    display: none;
}

.stepper__steps-list {
    /*min-height: 80vh;*/
    margin: 10rem 4rem 10rem 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    z-index:100;
}

.stepper__steps-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.stepper__steps-title {
    margin:0;
}

.stepper__steps-item-spacer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-right: 2px solid #01b57a;
    margin: 0.5rem -1px 0.5rem 0;
    min-height:1.5rem;
}

.stepper__steps-item-title {
    padding-right:4rem;
}

.stepper__steps-item-circle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 4rem;
    height: 4rem;
    margin-right:-2rem;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #01b57a;
    font-size: 2.5rem;
    font-family: "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    color: #01b57a;
    font-weight: bold;
    aspect-ratio: 1;
}

.stepper__steps-item-complete {
    background: #01b57a;
    color:white;
}

@media(max-width: 850px) {

    .stepper__steps-mobile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        padding: 0 1.5rem;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
    }

    .stepper__steps-mobile > *:first-child {
        text-align: left;
    }
    .stepper__steps-mobile *:last-child {
        text-align: right;
    }

    .stepper__steps-list {
        min-height: auto;
        margin: 0px 2rem;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        justify-items: center;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: start;
    }

    .stepper__steps-item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
    }

    .stepper__steps-item-wrapper {
        display:none;
    }

    .stepper__steps-item-circle {
        width:2rem;
        height:2rem;
        margin:0;
        margin-top:-1rem;
        font-size:1.25rem;
    }

    .stepper__steps-item-spacer {
        border-right:none;
        border-top: 2px solid #01b57a;
        margin: -1px 0.5rem 0 0.5rem;
        min-height: initial;
        min-width: 0.5rem;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
    }

}</style>