<script>
    import NoSidebar from '../../Shared/Layouts/NoSidebar.svelte'
  import api from "../../Shared/api";
  import {signIn as setUserSignedIn} from "../../Shared/store";
  import recaptcha from "../../Shared/recaptcha";
  import {router} from "tinro";

  let email = "", password = "", error = "";
  let working = false;

  function handleEnter(e) {
    if (!working && e.key === "Enter") {
      signIn();
    }
  }

  async function signIn() {
    error = "";
    if (!email) {
      error = "Please enter your e-mail address"
      return;
    }
    if (!/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(email.trim())) {
      error = "Please enter a valid e-mail address";
      return;
    }
    if (!password) {
      error = "Please enter your password"
      return;
    }
    working = true;

    recaptcha.action("sign_in", async (token) => {
      const result = await api.signIn(email, password, token);
      working = false;
      if (!result.success) {
        error = result.error || "Invalid credentials, please try again.";
        return;
      }
      const user = {
        token: result.token,
        profile: {name: email},
        expires: Date.now() + result.expiresIn * 1000,
        requiresVerification: result.requiresVerification
      };
      setUserSignedIn(user);
      router.goto("/");
    });

  }
</script>

<NoSidebar title="Sign In">
    <div class="signin">
        <p>Please enter your username and password below:</p>
        <div class="form">
            <label for="email">E-mail Address:</label>
            <input id="email" type="email" bind:value={email} on:keyup={handleEnter}/>
            <label for="password">Password:</label>
            <input id="password" type="password" bind:value={password} on:keyup={handleEnter}/>
        </div>
        {#if error}
            <span class="error">{error}</span>
        {/if}
        <button class="button" disabled={working} on:click={signIn}>{ working ? "Please Wait..." : "Sign In" }</button>
        <div class="links">
            <p>
                <a href="/reset">Forgot password?</a>
            </p>
            <p>
                <a href="/register">Need an account? Register here!</a>
            </p>
        </div>
    </div>
</NoSidebar>

<style>

.signin {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.form {
    margin-bottom:1rem;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1em;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.error {
    margin-bottom:1rem;
    color:red;
}

.links {
    margin-top:1rem;
}</style>
