<script>

    import {getContext, createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    import Callback from "../../Callback.svelte";
    import api from "../../../api";
    import {activePortal} from "../../../store";

    /* From Context */
    const accessCode = getContext('accessCode');
    const portal = getContext('portal');

    /* Passed Vars */
    export let quote;

    /* Local Vars */
    let working = false;
    let searchConsent = false;

    /* Functions */
    async function online() {
        working = true;
        quote = await api.autoAipUpdate(portal, accessCode, "online");
        working = false;
        dispatch("online");
    }

</script>

<div class="container auto-aip__offline">
    <h1>Thank you!</h1>
    <p>Your callback has been registered and one of our award winning team will be in touch.</p>
    <h3>Continue online?</h3>
    <p>If you would prefer to complete your quote online instead, you can!</p>
    {#if quote.creditSearchConsentRequired}
        <p>To proceed online we need your consent to run a soft quotation credit search. This won't affect your credit rating - you can find more information in our <a
                href="https://{ $activePortal.brand.domain }/terms"
                target="_blank">terms &amp; conditions</a> and <a href="https://{ $activePortal.brand.domain }/privacy"
                                                                  target="_blank">privacy policy</a>.</p>
        <p>
            <a href="#" on:click|preventDefault="{() => { searchConsent = !searchConsent}}" class={`consent ${searchConsent ? 'green' : ''}`}>
                <span class="check">&#10004;</span><span>I accept</span>
            </a>
        </p>
    {/if}
    <button class="button pill fill" on:click={() => online()} disabled={working || (quote.creditSearchConsentRequired && !searchConsent)}>Continue</button>
</div>

<style>


.auto-aip__offline {
    width: 100%;
    margin-top: 1rem;
    padding: 1rem;
    background-color: white;
    border: 3px solid #01b57a;
    border-radius: 0.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-line-pack: stretch;
        align-content: stretch;
    text-align: center;
}

.auto-aip__offline__options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0.5em;
}

.auto-aip__new__options > div {
    width: 50%;
    text-align:center;
    margin:1em 2em;
    padding:1em;
    background-color: #d5f0f5;
    border-radius: 1em;
}

.auto-aip__new__options > div> h2 {
    padding:0;
    margin:0;
}

p {
    line-height: 25px;
}

button {
    display: block;
    margin: 1em auto 1em auto;
}

button[disabled] {
    background-image: -webkit-gradient(linear, left top, left bottom, from(lightgray), to(gray));
    background-image: linear-gradient(to bottom, lightgray 0%, gray 100%);
    border-color: darkgray;
}

.consent {
    font-size:1.25rem;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap:0.5rem;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0.5rem 1rem;
    text-decoration: none;
}

.consent .check {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    width:2rem;
    height:2rem;
    border: 2px solid #bfe5ee;
    border-radius: 50%;
    background-color: white;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color:white;
}

.consent.green .check {
    color: #00a854;
    border-color: #00a854;
}</style>