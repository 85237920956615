<script>
    import { router } from "tinro";
    import {activeCase, activePortal} from "../../store";
    $: isProtect = $activeCase.portal.api === 'protect';
    $: myTab = $activePortal.term != undefined ? $activePortal.term : "Case";
</script>

<div class="navigation">
    <nav>
        <a class="button" href="/" class:active={$router.path === "/"}>My { myTab }</a>
        {#if !isProtect}
            <a class="button" href="/todo" class:active={$router.path === "/todo"}>To-Do List</a>
        {/if}
        <a class="button" href="/documents" class:active={$router.path === "/documents"}>Documents</a>
    </nav>
</div>

<style>

nav {
    margin: 5rem 6rem 5rem 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    gap: 1rem;
    z-index:100;
}

.button {
    white-space: nowrap;
    width:100%;
}

@media(max-width: 850px) {
    .navigation {
        border-top: 1px solid #cccccc;
    }
    nav {
        padding:0.5rem;
        margin: 0px 2rem;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: space-evenly;
            -ms-flex-pack: space-evenly;
                justify-content: space-evenly;
        justify-items: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }

    nav > a {
        display:-webkit-box;
        display:-ms-flexbox;
        display:flex;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }

}

@media(max-width: 500px) {
    .button.active {
        display:none;
    }
    .sidebar.protection-sidebar nav .button:not(:first-child) {
        display:none;
    }
}</style>