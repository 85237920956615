<NoSidebar title="Believe Guides" titleClass="text-left">

    <div class="guides">

        <p>If you need help using our app, web portal or information on the documents we need and their image quality, please select a guide below.</p>

        <h4>App Portal Guides</h4>
        <p><a href="/guide/app">Believe Android/iOS App Portal Guide</a></p>

        <h4>Web Portal Guides</h4>
        <p><a href="/guide/web">Believe Web Portal Guide</a></p>

        <h4>Document Guides</h4>
        <p><a href="/guide/acceptable-documents">Acceptable Documents/Image Quality</a></p>

    </div>

</NoSidebar>

<script>
    import NoSidebar from '../../Shared/Layouts/NoSidebar.svelte'
</script>

<style type="text/css">
.guides {
    text-align: left;
}
.guides p,
.guides ul li {
    line-height: 1.75rem;
}
.guides img {
    border: 1px solid #17174c;
    border-radius: 5px;
    margin: 2rem auto;
    max-width: 400px;
    max-height: 500px;
}
.guides .img-aside {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
}
.guides .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-bottom: 1px solid #17174c;
}
@media screen and (max-width: 700px) {
    .guides img {
        max-width: 100%;
    }
}
@media screen and (max-width: 1000px) {
    .guides .row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
}
@media screen and (max-width: 1200px) {
    .guides .img-aside {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
}</style>