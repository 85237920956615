<NoSidebar title="Believe Android/iOS App Portal Guide" titleClass="text-left">

    <a href="/guide">Back to Guides</a>

    <div class="app-guide">

        <p>This user guide will take you through how to register and activate your unique customer portal. It will also detail how to:</p>

        <ul>
            <li>upload the requested documents</li>
            <li>supply any additional information</li>
            <li>
                <a href="/guide/acceptable-documents">what the lenders accept as documents / quality of images</a>
            </li>
            <li>deleting your portal account</li>
        </ul>

        <hr/>

        <h3>Install the Believe App</h3>

        <p>As part of the email which your Advisor will have sent you detailing the product we have found for you, there will be a link for you to download our app from the Google Play Store or Apple App Store.</p>

        <p>Alternatively, visit the Google Play Store or Apple App Store and search for 'Believe Money'.</p>

        <img class="img-fluid" src="/guide/app/app_installed.png"/>

        <h3>Activation of your Portal Account</h3>

        <p>Once you have opened the app you will be shown the sign in screen.</p>

        <img class="img-fluid" src="/guide/app/welcome.png"/>

        <p>Once you have selected the &quot;New Customer? Register here!&quot; option, you will be taken to the register your e-mail address and to choose a password page.</p>

        <p><strong>Please note that the email you use during the registration process must be the same one you have provided to the advisor during your fact find and will be different for each applicant.</strong></p>

        <img class="img-fluid" src="/guide/app/register.png"/>

        <p>Once you have registered, you will be prompted to select a verification method that works for you, simply enter the code you received on the next screen.</p>

        <img class="img-fluid" src="/guide/app/verify.png"/>

        <p>You will not be able to log in to your portal until the verification step has been completed and you will be prompted to do so if you attempt to sign in at a later date.</p>

        <p>Once verified, you will be signed into your portal account and shown the home screen.</p>

        <img class="img-fluid" src="/guide/app/portal_home.png"/>

        <h3>Accessing an Activated Portal</h3>

        <p>If you have already activated your portal, simply open the app, input your email and password and sign into your account.</p>

        <p><strong>Please Note: You may be prompted to use biometrics, such as FaceID/TouchID, for added security and to make signing in easier in the future.</strong></p>

        <img class="img-fluid" src="/guide/app/welcome.png"/>

        <p>The main customer portal screen will then load for you to use.</p>

        <img class="img-fluid" src="/guide/app/portal_home.png"/>

        <p>On this screen you have several options:</p>

        <dl>
            <dt>To Action/To Do List</dt>
            <dd>Highlights are remaining items (such as required documents) that need your attention</dd>
            <dt>Summary</dt>
            <dd>Give you a summary of your case with loan/mortgage details</dd>
            <dt>Status of my Loan/Mortgage</dt>
            <dd>The current status and brief explanation of where we are up to with your case</dd>
            <dt>Documents Folder</dt>
            <dd>Here you can view/download any documents you have sent to us and any documents we have sent to you</dd>
            <dt>Send To Us</dt>
            <dd>If there is a document you think we need that isn't in your &quot;To Action/To Do List&quot; you can upload it here</dd>
        </dl>

        <h3>Multiple Cases</h3>

        <p>In the event you have multiple cases with us (either loans or mortgages), select your account/settings in the top right corner.</p>

        <img class="img-fluid" src="/guide/app/my_account.png"/>

        <p>
            You will find an option to &quot;Change Case&quot;, this will allow you to switch easily between your cases.
        </p>

        <img class="img-fluid" src="/guide/app/case_selector.png"/>

        <h3>Signing your Application Pack</h3>

        <p>To sign your Application pack you must be in the ‘To Action/To Do List’ tab and then you will need to select the ‘Proceed to Sign’ button. Once in the form you then have the ability to sign the document.</p>

        <img class="img-fluid" src="/guide/app/todolist_applicationpack.png"/>

        <p>Once you are in the sign document page, you can then select the signature applicant box which will open up a signature box.</p>

        <img class="img-fluid" src="/guide/app/sign_document.png"/>

        <p><strong>You will only be able to sign for yourself, any other applicants will also need to complete this step.</strong></p>

        <p>Once you have selected the ‘Signature applicant’ and have been presented with the signature box. Simply draw you signature inside the box with your finger or your mouse and either select ‘Clear’ to start again or select ‘Sign’ to accept the signature.</p>

        <img class="img-fluid" src="/guide/app/draw_signature.png"/>

        <h3>Uploading your documents</h3>

        <p>To upload the required documents, you need to click on the ‘To Action/To Do List’ tab.</p>

        <p>Once you have done this, you will then be presented with a list of the requirements that were added to your case and sent to you at the point of sale.</p>

        <img class="img-fluid" src="/guide/app/todolist.png"/>

        <p>Tap on an item and then choose the document from a file of your choosing, or to take a photo of the document and upload it (screen shots are not recommended for uploading).</p>
        <p><strong>Each applicant will be able to see the requirements for each other and have the ability to upload the files on their behalf. Please note though you will not be able to sign any forms for them and this must be done by the correct applicant via their own portal.</strong></p>

        <p>When you have chosen the documents, tap the ‘Upload’ button, the document will then upload, and a tick will appear to the left of the documentation field to indicate the upload has occurred.</p>
        <p><strong>Please note – for a request of multiple documents – i.e. 3 x latest payslips - please add all three files before clicking Upload.</strong></p>

        <div class="img-aside">
            <img class="img-fluid" src="/guide/app/todolist_payslips.png"/>
            <img class="img-fluid" src="/guide/app/todolist_payslips_upload.png"/>
        </div>

        <p>Once these steps have been completed, we will receive the files and be able to check to ensure that the lender will accept them and continue to progress your case.</p>
        <p><a href="/guide/acceptable-documents">View our guide of acceptable documents and quality here</a>.</p>

        <h3>Deleting your portal account</h3>

        <p>In the event you wish to delete your portal account, select your account/settings in the top right corner.</p>

        <img class="img-fluid" src="/guide/app/my_account.png"/>

        <p>
            You will find an option to &quot;Delete Account&quot;, this will ask you to confirm your password and remove your portal account.
        </p>

        <img class="img-fluid" src="/guide/app/delete_account.png"/>

    </div>

</NoSidebar>

<script>
    import NoSidebar from '../../../Shared/Layouts/NoSidebar.svelte'
</script>

<style type="text/css">
.app-guide {
    text-align: left;
}
.app-guide p,
.app-guide ul li {
    line-height: 1.75rem;
}
.app-guide img {
    border: 1px solid #17174c;
    border-radius: 5px;
    margin: 0 auto 2rem auto;
    max-width: 600px;
    max-height: 500px;
}
.app-guide .img-aside {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
}
.app-guide dt {
    font-weight: bold;
}
.app-guide dd {
    padding: 1rem 0 1rem 0;
}
@media screen and (max-width: 925px) {
    .app-guide img {
        max-width: 100%;
    }
}
@media screen and (max-width: 800px) {
    .app-guide .img-aside {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
}</style>