<script>

    import {getContext, createEventDispatcher, onMount} from 'svelte';
    const dispatch = createEventDispatcher();
    import api from "../../../api";
    import { additionalFieldsConfig } from "../../../additionalFields";
    import {router} from "tinro";
    import Fields from "../Components/Fields.svelte";

    /* From Context */
    const accessCode = getContext('accessCode');
    const portal = getContext('portal');

    $: isFirstCharge = portal === "first-charge";

    /* Passed Vars */
    export let quote;

    /* Local Vars */
    let working = false;
    let additionalFields = { };

    // Mount
    onMount(() => {
        fields.forEach(field => {
            /*
            let value = field.value;
            if (field.config.dataType === 'number') {
                value = value ? value : 0;
            }
            if (field.config.dataType === "dropdown") {
                value = value ? value : '';
            }
            if (field.config.dataType === "conditional") {
                value = field.config.check(field.value);
                additionalFields[field.name + '-value'] = field.value;
            }
            additionalFields[field.name] = value;
            */
            additionalFields[field.name] = null;
        });
    })

    /* Functions */
    const addAdditionalFields = async () => {
        working = true;
        let updates = {};
        fields.forEach(field => {
            if (field.config.dataType === "conditional") {
                updates[field.name] = additionalFields[field.name + '-value'];
            } else {
                updates[field.name] = additionalFields[field.name];
            }
        });
        quote = await api.autoAipUpdate(portal, accessCode, "fields", updates, "PUT");
        working = false;
        dispatch('submit');
    }

    $: fields = (quote.requiredFields ? quote.requiredFields : []).map(x => {
        return {
            ...x,
            config: additionalFieldsConfig[x.name]
        };
    })

</script>

<div class="container auto-aip__additional">
    {#if isFirstCharge}
        <h1>Your Personalised Mortgage</h1>
        <h2>Compare instant mortgage deals, hassle free!</h2>
        <p>Tell us some basics to start searching for offers:</p>
    {:else}
        <h1>Additional Information Required</h1>
        <p>Please provide the following information to progress your quote.</p>
    {/if}
    <form class="form" on:submit|preventDefault={addAdditionalFields}>
        <div class="auto-aip__additional__fields">
            {#each fields as field}
                <div>
                    <h3>{field.config.title}</h3>
                    <p>{field.config.description}</p>
                    <div>
                        <Fields
                           fieldName={field.name}
                           dataType={field.config.dataType}
                           required={field.required}
                           options={field.config.options}
                           conditions={field.config.conditions}
                           bind:value={additionalFields[field.name]}
                        />
                    </div>
                    {#if field.config.dataType === "conditional"}
                        {#each field.config.conditions
                            .filter(x => x.value === additionalFields[field.name] && x.field)
                            .map(x => x.field) as condition
                        }
                            <p>{condition.description}</p>
                            <div>
                                <Fields
                                        fieldName={field.name}
                                        dataType={condition.dataType}
                                        required={field.required}
                                        bind:value={additionalFields[field.name+'-value']}
                                />
                            </div>
                        {/each}
                    {/if}
                </div>
            {/each}
        </div>
        <button class="button green mt pill fill"
                type="submit"
                disabled={working}
        >Submit</button>
    </form>

</div>

<style>


.auto-aip__additional {
    width: 100%;
    margin-top: 1rem;
    padding-top: 1rem;
    background-color: white;
    border: 3px solid #01b57a;
    border-radius: 0.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-line-pack: stretch;
        align-content: stretch;
    text-align: center;
}

.auto-aip__additional .form {
    padding-bottom: 1.5rem;
}

.auto-aip__additional__fields {
    margin: 0 auto;
    max-width: 650px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap:1rem;
    padding:1rem;
 }

.auto-aip__additional__fields > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: white;
    padding: 2rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-preferred-size: 40%;
        flex-basis: 40%;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -ms-flex-line-pack: stretch;
        align-content: stretch;
    border-radius: 0.5rem;
}

.auto-aip__additional__fields > div h3 {
    margin:0;
    padding:0;
}

.auto-aip__additional__fields > div p {
    padding: 0 1rem;
}

.auto-aip__additional__fields form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

@media(max-width: 1100px) {
    .auto-aip__additional__fields {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
}

@media(max-width: 850px) {
    .auto-aip__additional {
        width: 100%;
        padding-top:1rem;
    }
    .auto-aip__additional h1 {
        font-size:20px;
    }
    .auto-aip__additional p {
        font-size:14px;
        padding: 0 0.5rem;
    }
    .auto-aip__additional__fields {
        padding:0;
    }
}</style>
