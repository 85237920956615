<script>
    import SidebarNavigation from "../Shared/Layouts/SidebarNavigation.svelte";
    import {activeCase, activeCaseData} from "../Shared/store";
    import InsurancePanel from "../Shared/Components/InsurancePanel.svelte";

    $: protection = $activeCaseData && $activeCaseData.protection && $activeCaseData.protection.length > 0 ? $activeCaseData.protection : [];

</script>

<SidebarNavigation class="protection-sidebar">
    {#if $activeCase}
        <div class="protection">
            <div class="container">
                <h1>Protection Quote</h1>
                <h3 class="call-out">If the worst was to happen, how would you look after your family and protect your home?</h3>

                <div class="protection__insurance-group">
                    {#each protection as quote}
                    <InsurancePanel
                            title={quote.title}
                            color={quote.colour}
                            risk={quote.risk.risk}
                            outOf={quote.risk.outOf}
                            groupedBy={quote.risk.groupedBy}
                            chance={quote.risk.chance}
                            premium={quote.premium}
                            mortgage={quote.mortgage}
                    />
                    {/each}
                </div>

                <h3 class="call-out bold">These calculations are to offer guidance of your protection shortfalls, to determine your specific personal needs contact your Financial Adviser</h3>

                <div class="protection__next-steps">
                    <h2>Next Steps</h2>
                    <p>
                        If you would like us to proceed with this quote, let us know and return the requested documentation.
                        We will then take it from there for you. You can call us on <strong>{ $activeCase.portal.brand.telephone }</strong>.
                    </p>
                    <a class="button call" href="tel:+441302590873">Call Us</a>
                </div>

                <div class="protection__insurance-key">
                    <h4>Life Insurance</h4>
                    <p>Nominee receives a lump sum to settle your mortgage if you die.</p>
                    <h4>Income Protection</h4>
                    <p>Keep up with your mortgage payments if you are off work sick due to long-term illness or disability.</p>
                    <h4>Critical Illness Cover</h4>
                    <p>Receive a lump sum to cover medical expenses or pay off your mortgage if you are diagnosed with a critical illness.</p>
                </div>

                <div class="protection__disclaimer small">
                    <h4>Disclaimer</h4>
                    <p>This is not a quote or an offer of insurance and is not intended to replace the advice of vour financial adviser. Indicative costs for cover have been calculated on the following levels of cover:</p>
                    <ul>
                        <li>Life: £150,000</li>
                        <li>Life or Earlier Critical Illness: £150.000</li>
                        <li>Income Protection: £1,400 per month benefit for a minimum of 5 years, with a deferred period of 3 months.</li>
                    </ul>
                    <p>The prices given may change and are based on your age, smoker status and employment details. The amount and type of cover recommended by your financial adviser may vary based on their assessment of your needs.</p>
                    <p>The likelihood of each scenario has been calculated based on industry and Pipeline data. Where there is not enough information to calculate these risks, no figure will be given.</p>
                </div>

            </div>
        </div>
    {/if}
</SidebarNavigation>

<style>
.protection {
    padding:0 1rem;
}

.protection h3.call-out {
    line-height: 1.75rem;
}

.protection .bold {
    font-weight: bold;
}

.protection__insurance-key {
    border-radius: 0.75rem;
    border: 2px solid #e5e5e5;
    padding: 1rem;
    margin-top:1rem;
}

.protection__insurance-key h3 ,
.protection__insurance-key h4,
.protection__insurance-key p {
    margin:0;
    padding:0;
}
.protection__insurance-key :not(p:last-child) {
    padding: 0.5rem 0;
}

.protection__insurance-group {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap:1rem;
}

.protection p.bold {
    font-weight: bold;
}

/* change when less than 1500px */
@media (max-width: 1000px) {
    .protection__insurance-group {
        /* one column */
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width:  850px) {
    .protection {
        padding:0;
    }
    .protection__disclaimer {
        padding-top:1rem;
    }
    .protection__insurance-group {
        grid-auto-rows: initial;
    }
}</style>